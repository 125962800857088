// Form updates
export const ACTIONS = {
    SELECT_INSTALLATION: 'form/SELECT_INSTALLATION',
    SET_HAS_EXISTING_INSTALLATION: 'form/SET_HAS_EXISTING_INSTALLATION',
    SET_HAS_SBM_ACCOUNT: 'form/SET_HAS_SBM_ACCOUNT',
    SET_USER_TYPE: 'form/SET_USER_TYPE',
    SET_REQUEST_DAIKIN_ASSISTANCE : 'form/SET_REQUEST_DAIKIN_ASSISTANCE',
    SET_VISIT_TYPE: 'form/SET_VISIT_TYPE',
    SET_VISIT_DATE: 'form/SET_VISIT_DATE',
    SELECT_SERVICEPARTNER: 'form/SELECT_SERVICEPARTNER',
    SET_SERVICEPARTNER_KNOWN: 'form/SET_SERVICEPARTNER_KNOWN',
    SET_SERVICE_BY_DAIKIN: 'form/SET_SERVICE_BY_DAIKIN',
    SET_URGENCY: 'form/SET_URGENCY',
    SET_USER_DETAILS: 'form/SET_USER_DETAILS',
    SET_USER_ADDRESS: 'form/SET_USER_ADDRESS',
    SET_CONTACT_TYPE: 'form/SET_CONTACT_TYPE',
    SET_CONTACT_PERSON_DETAILS: 'form/SET_CONTACT_PERSON_DETAILS',
    SET_COMMENT: 'form/SET_COMMENT',
    SET_IS_SAME_ADDRESS: 'form/installation/SET_IS_SAME_ADDRESS',
    SET_INSTALLATION_ADDRESS: 'form/installation/address/SET_INSTALLATION_ADDRESS',
    SET_SERIAL_KNOWN: 'form/installation/details/SET_SERIAL_KNOWN',
    SET_INSTALLATION_VIA_SERIAL: 'form/installation/details/SET_VIA_SERIAL',
    SET_INSTALLATION_VIA_DESCRIPTION: 'form/installation/details/SET_VIA_DESCRIPTION',
    SET_TECHNICAL_ANSWERS: 'form/SET_TECHNICAL_ANSWERS',
    SET_LEGAL_AGREEMENT_ACCEPTED: 'form/legal/SET_ACCEPTED',
    FETCH_PRICING_INFO: 'form/FETCH_PRICING_INFO',
    SET_PRICING_INFO: 'form/SET_PRICING_INFO',
    // Submit
    SUBMIT: 'SUBMIT',
    SET_ERRORS: 'SET_ERRORS',
    // Service requests
    QUERY_SERVICEPARTNERS: 'req/service-partners/QUERY',
    SUGGEST_SERVICEPARTNERS: 'req/service-partners/SUGGEST',
    ADD_SERVICEPARTNER_DETAILS: 'req/service-partners/ADD-DETAILS',
    SET_SERVICE_PARTNER_LOADING: 'req/service-partners/SET-LOADING',
    // Existing installations
    QUERY_INSTALLATIONS: 'req/existing-installations/QUERY',
    SUGGEST_INSTALLATIONS: 'req/existing-installations/SUGGEST',
    // Materials
    QUERY_MATERIALS: 'req/materials/QUERY',
    SUGGEST_MATERIALS: 'req/materials/SUGGEST',
    // Product ranges
    SUGGEST_PRODUCTRANGES: 'req/productranges/SUGGEST',
};

// Form updates
export const selectInstallation = value => ({ type: ACTIONS.SELECT_INSTALLATION, value });
export const setHasExistingInstallation = value => ({ type: ACTIONS.SET_HAS_EXISTING_INSTALLATION, value });
export const setHasSbmAccount = value => ({ type: ACTIONS.SET_HAS_SBM_ACCOUNT, value });
export const setUserType = userType => ({ type: ACTIONS.SET_USER_TYPE, userType });
export const setRequestDaikinAssistance = () => ({ type: ACTIONS.SET_REQUEST_DAIKIN_ASSISTANCE });
export const setVisitType = visitType => ({ type: ACTIONS.SET_VISIT_TYPE, visitType });
export const setVisitDate = visitDate => ({ type: ACTIONS.SET_VISIT_DATE, visitDate });
export const selectServicePartner = servicePartner => ({ type: ACTIONS.SELECT_SERVICEPARTNER, servicePartner });
export const setServicePartnerKnown = value => ({ type: ACTIONS.SET_SERVICEPARTNER_KNOWN, value });
export const setServiceByDaikin = value => ({ type: ACTIONS.SET_SERVICE_BY_DAIKIN, value });
export const setUrgency = value => ({ type: ACTIONS.SET_URGENCY, value });
export const setUserDetails = data => ({ type: ACTIONS.SET_USER_DETAILS, data });
export const setUserAddress = data => ({ type: ACTIONS.SET_USER_ADDRESS, data });
export const setContactType = contactType => ({ type: ACTIONS.SET_CONTACT_TYPE, contactType });
export const setContactPersonDetails = data => ({ type: ACTIONS.SET_CONTACT_PERSON_DETAILS, data });
export const setComment = comment => ({ type: ACTIONS.SET_COMMENT, comment });
export const setIsSameAddress = isSameAddress => ({ type: ACTIONS.SET_IS_SAME_ADDRESS, isSameAddress });
export const setInstallationAddress = data => ({ type: ACTIONS.SET_INSTALLATION_ADDRESS, data });
export const setSerialKnown = known => ({ type: ACTIONS.SET_SERIAL_KNOWN, known });
export const setInstallationDetailsViaSerial = data => ({ type: ACTIONS.SET_INSTALLATION_VIA_SERIAL, data });
export const setInstallationDetailsViaDescription = data => ({ type: ACTIONS.SET_INSTALLATION_VIA_DESCRIPTION, data });
export const setTechnicalAnswers = data => ({ type: ACTIONS.SET_TECHNICAL_ANSWERS, data });
export const setLegalAgreementAccepted = accepted => ({ type: ACTIONS.SET_LEGAL_AGREEMENT_ACCEPTED, accepted });
export const fetchPricingInfo = () => ({ type: ACTIONS.FETCH_PRICING_INFO });
export const setPricingInfo = pricingInfo => ({ type: ACTIONS.SET_PRICING_INFO, pricingInfo });
// Submit
export const submit = () => ({ type: ACTIONS.SUBMIT });
export const setErrors = errors => ({ type: ACTIONS.SET_ERRORS, errors });
// Service partners typeahead
export const queryServicePartners = query => ({ type: ACTIONS.QUERY_SERVICEPARTNERS, query: query.trim() });
export const suggestServicePartners = results => ({ type: ACTIONS.SUGGEST_SERVICEPARTNERS, results });
export const addServicePartnerDetails = (id, details) => ({ type: ACTIONS.ADD_SERVICEPARTNER_DETAILS, id, details });
export const setServicePartnerLoading = isLoading => ({ type: ACTIONS.SET_SERVICE_PARTNER_LOADING, isLoading });
// Existing installations typeahead
export const queryInstallations = query => ({ type: ACTIONS.QUERY_INSTALLATIONS, query: query.trim() });
export const suggestInstallations = results => ({ type: ACTIONS.SUGGEST_INSTALLATIONS, results });
// Materials typeahead
export const queryMaterials = query => ({ type: ACTIONS.QUERY_MATERIALS, query });
export const suggestMaterials = results => ({ type: ACTIONS.SUGGEST_MATERIALS, results });
// Product ranges options
export const suggestProductRanges = results => ({ type: ACTIONS.SUGGEST_PRODUCTRANGES, results });
