import i18n from '@common/i18n';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from '../actions';
import Guard from '../controls/Guard';
import { SESSION_USER_TYPES } from '../reducers/settingsReducer';
import { renderSubmit } from '../selectors';

const Submit = () => {
    const dispatch = useDispatch();
    const error = useSelector(store => store.errors.submit);
    const isSubmitting = useSelector(store => store.settings.isSubmitting);
    const userType = useSelector(store => store.settings.userType);
    const impersonation = useSelector(store => store.settings.impersonation); // boolean

    // Cannot submit rfa form is user is impersonating and user is an end-user
    const isDisabled = userType === SESSION_USER_TYPES.END && impersonation;

    return (
        <section className='form-section'>
            {error && (
                <label className='alert alert-danger unexpected-error'>
                    {error}
                </label>
            )}
            {isSubmitting ? (
                <a type='button' className='btn btn-primary pull-right disabled'>
                    {i18n('loading')}
                </a>
            ) : (
                <button
                    type='button'
                    disabled={isDisabled}
                    onClick={isDisabled ? undefined : (e) => {
                        e.preventDefault();
                        dispatch(submit());
                    }}
                    className='btn btn-primary pull-right'
                >
                    {i18n('submit')}
                </button>
            )}
        </section>
    );
};

export default () => <Guard selector={renderSubmit}><Submit /></Guard>;
