import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserType } from '../actions';
import { renderUserType } from '../selectors';
import { USER_TYPES } from '../reducers/formReducer';
import RadioChoice from '../controls/RadioChoice';
import Guard from '../controls/Guard';

const userTypeChoices = [
    { label: 'user_type_end_user', info: null, value: USER_TYPES.END },
    { label: 'user_type_key_account', info: 'user_type_key_account_info', value: USER_TYPES.KEY },
    { label: 'user_type_professional', info: null, value: USER_TYPES.PRO },
];

const UserType = () => {
    const dispatch = useDispatch();
    const userType = useSelector(store => store.form.userType);
    return (
        <RadioChoice
            id='user_type'
            label='user_type'
            choices={userTypeChoices}
            onChange={value => dispatch(setUserType(value))}
            selectedValue={userType}
            required
        />
    );

};

export default () => <Guard selector={renderUserType}><UserType /></Guard>;
