import { ACTIONS } from '../actions';

const defaultState = {
    suggestions: [],
    details: {},
};

const materialSuggestionsReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SUGGEST_MATERIALS:
            // Suggest a couple of materials
            if (action.results) {
                return {
                    ...state,
                    // Only show first 5 results and map strings to { id, name }-objects
                    suggestions: action.results.map(id => ({ id, name: id })).slice(0, 5),
                };
            }
            return { ...state, suggestions: defaultState.suggestions };
        case ACTIONS.SET_INSTALLATION_VIA_SERIAL:
            // If material was selected -> clear out suggestions
            return action.data.material ? { ...state, suggestions: defaultState.suggestions } : state;
        default:
            return state || defaultState;
    }
};

export default materialSuggestionsReducer;
