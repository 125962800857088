import axios from 'axios';
import { createLogic } from 'redux-logic';
import { suggestInstallations, suggestProductRanges, ACTIONS } from '../actions';

const fetchInstallationLogic = createLogic({
    type: ACTIONS.QUERY_INSTALLATIONS,
    debounce: 700,
    latest: true,

    process({ getState, action }, dispatch, done) {
        const { query } = action;
        if (query.length <= 2) {
            done();
            return;
        }
        const request = getState().settings.urls.installationSearch.replace('__query__', query);
        axios.get(request)
            .then(res => {
                if (res.status === 200 && res.data) dispatch(suggestInstallations(res.data));
            })
            .catch(() => { /* TODO */ })
            .finally(done);
    },
});

const fetchProductRangesLogic = createLogic({
    type: ACTIONS.SET_INSTALLATION_VIA_SERIAL,

    process({ getState, action }, dispatch, done) {
        const { material } = action.data;
        if (material && material.id) {
            const request = getState().settings.urls.productRange.replace('__material__', material.id);
            axios.get(request)
                .then(res => {
                    if (res.status === 200 && res.data) dispatch(suggestProductRanges(res.data));
                })
                .catch(() => { /* TODO */ })
                .finally(done);
        } else {
            done();
        }
    },
});

export default [fetchInstallationLogic, fetchProductRangesLogic];
