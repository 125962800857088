import i18n from '@common/i18n';
import React from 'react';

const Radio = ({ id, label, info, value, isSelected, onChange }) => (
    <div className='form-check form-check-inline'>
        <label
            className='form-check-label'
            onClick={isSelected ? null : () => onChange(value)}
            htmlFor={id}
        >
            <input
                name={id}
                type='radio'
                value={value}
                checked={isSelected}
                readOnly
            />
            {` ${i18n(label)}`}
        </label>

        {info && <div><small className='text-muted'>{i18n(info)}</small></div>}
    </div>
);

const RadioChoice = ({ id, label, subLabel, choices, onChange, selectedValue, required, error }) => (
    <div className={`form-group form-fields${required ? ' required' : ''}`}>
        <label className='form-label'>{i18n(label)}</label>
        {subLabel && <small className='text-muted'>{i18n(subLabel)}</small>}
        <div className={`form-fieldset ${error ? 'error' : ''}`}>
            {choices.map(choice => {
                const choiceId = `${id}-${choice.value.toString()}`;
                return (
                    <Radio
                        id={choiceId}
                        key={choiceId}
                        label={choice.label}
                        info={choice.info}
                        value={choice.value}
                        isSelected={choice.value === selectedValue}
                        onChange={onChange}
                    />
                );
            })}
        </div>
        {error && <label className='error-msg'>{error}</label>}
    </div>
);

export default RadioChoice;
