import { getInstallationServicePartner } from '.';
import { USER_TYPES, VISIT_TYPES } from '../reducers/formReducer';
import { SESSION_USER_TYPES } from '../reducers/settingsReducer';

const isProPortal = store => store.settings.userType === SESSION_USER_TYPES.PRO;
const isEndUser = store => store.settings.userType === SESSION_USER_TYPES.END;
const isAnon = store => store.settings.userType === SESSION_USER_TYPES.ANON;

// Only render when request was started from request-overview on professional-portal
export const renderExistingInstallationPro = store => isProPortal(store);
export const renderExistingInstallationEnd = store => isEndUser(store);

// When opening RFA from an existing installation, the user should not be able to change it
export const lockExistingInstallation = store => !!store.settings.installation;

// This is a helper method determining if several of the components should be rendered or not
// eg.: VisitDetails, Contact, Comment, Installation-address & -details, Legal, ...
const completeFormTreshold = store => {
    const { userType, servicePartnerKnown, serviceByDaikin, hasSbmAccount } = store.form;
    // Always render on professional portal
    if (isProPortal(store)) return true;
    // Always render for logged in end-users
    if (isEndUser(store)) return true;
    // Only perform these checks if user is anonymous
    if (isAnon(store)) {
        // Don't render if the user answered: "Yes I have an Sbm account"
        if (hasSbmAccount !== false) return false;
        // Don't render if userType is not selected yet
        if (!userType) return false;
        // Render if userType is professional (he does not need a service-partner)
        if (userType === USER_TYPES.PRO) return true;
    }
    // Render if service partner is not kwown
    if (servicePartnerKnown === false) return true;
    // Don't render if the user wants service by his service-partner
    if (serviceByDaikin !== true) return false;
    return true;
};

// Only render "Do you have an account?" if the session-user is anonymous
export const renderHasAccount = store => isAnon(store);

// Only render User-type selection for anonymous users and anon selected "I don't have an account"
export const renderUserType = store => isAnon(store) && store.form.hasSbmAccount === false;

// Only render Service-partner selection for non-professionals
export const renderServicePartner = store => {
    const { userType, hasSbmAccount } = store.form;
    // Don't render on professional portal
    if (isProPortal(store)) return false;
    // Don't render if end-user portal is logged in
    if (isEndUser(store)) return false;
    // Don't render for users who stated they are a professional
    if (userType === USER_TYPES.PRO) return false;
    // Don't render if the user answered: "Yes I have an Sbm account"
    if (hasSbmAccount !== false) return false;
    // Don't render if userType is not selected yet
    if (!userType) return false;
    return true;
};

// Only render service-partner selection for end-users
export const renderServicePartnerEnd = store => {
    // Only applies for authenticated end-users
    if (!isEndUser(store)) return false;
    // Only applies for installations of which the current Service Partner is NOT of type Affiliate
    if (getInstallationServicePartner(store)?.profile === 'affiliate') return false;
    return true;
};

export const renderVisitDetailsPart = store => {
    const requestDaikinAssistance = store.form?.requestDaikinAssistance;
    if (renderServicePartnerEnd(store) && !requestDaikinAssistance) return false;
    return true;
};

export const renderVisitDetails = completeFormTreshold;

// Urgency is part of VisitDetails so no need for elaborate checks
export const renderUrgency = store => {
    // Urgency is hidden for maintenance visits on the pro-portal
    return !(isProPortal(store) && store.form.visitType === VISIT_TYPES.MAINTENANCE);
};

// Commossioning is part of VisitDetails so no need for elaborate checks
export const renderCommissioning = store => {
    if (isProPortal(store)) {
        // Only render on the professional-portal if there is no existing installation
        return !store.form.hasExistingInstallation;
    } else {
        // Render it if the user selected 'professional' on the public portal
        return store.form.userType === USER_TYPES.PRO;
    }
};

export const renderUserDetails = store => {
    // Don't render on professional portal
    if (isProPortal(store)) return false;
    if (isEndUser(store)) return false;
    if (!renderVisitDetailsPart(store)) return false;
    return completeFormTreshold(store);
};

export const renderContact = store => {
    if (!renderVisitDetailsPart(store)) return false;
    return completeFormTreshold(store);
};

export const renderComment = store => {
    if (!renderVisitDetailsPart(store)) return false;
    return completeFormTreshold(store);
};

export const renderInstallationForms = store => {
    // Don't render if this request is for an existing installation (address is known)
    if (store.form.hasExistingInstallation) return false;
    if (isEndUser(store)) return false;
    return completeFormTreshold(store);
};

export const renderSameAddressCheckbox = store => !isProPortal(store);

export const renderCompanyField = store => {
    if (isAnon(store)) {
        // User is not logged in, check if he selected KEY or PRO as userType
        return [USER_TYPES.KEY, USER_TYPES.PRO].includes(store.form.userType);
    } else {
        // User is logged in, check if he is anything other than an END-user
        return store.settings.userType !== SESSION_USER_TYPES.END;
    }
};

export const renderLegal = store => {
    if (!renderVisitDetailsPart(store)) return false;
    return completeFormTreshold(store);
};

export const renderSubmit = store => {
    if (!renderVisitDetailsPart(store)) return false;
    return completeFormTreshold(store);
};

export const renderTechnicalQuestions = store => {
    // Are there any technicalQuestions available to render?
    if (!(store.settings.technicalQuestions?.length > 0)) return false;
    // Is serial known?
    if (!store.form.installationSerialKnown) return false;
    // Does the selected productRange require technicalQuestions?
    const requires_technical_questions = store.form.installationKnown?.productRange?.requires_technical_questions;
    if (!requires_technical_questions || requires_technical_questions === '0') return false;
    // Is this a comissioning request?
    if (!isProPortal(store) && store.form.userType !== USER_TYPES.PRO) return false;
    if (store.form.hasExistingInstallation) return false;
    if (store.form.visitType !== VISIT_TYPES.COMMISSIONING) return false;
    // Everything passed, show the questions
    return true;
};
