import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRequestDaikinAssistance, setUrgency, setVisitDate, setVisitType } from '../actions';
import Guard from '../controls/Guard';
import RadioChoice from '../controls/RadioChoice';
import { VISIT_TYPES } from '../reducers/formReducer';
import {
    renderCommissioning,
    renderServicePartnerEnd,
    renderUrgency,
    renderVisitDetails,
    renderVisitDetailsPart,
} from '../selectors';

const visitTypeChoices = [
    { label: 'visit_type_repair', value: VISIT_TYPES.REPAIR },
    { label: 'visit_type_maintenance', value: VISIT_TYPES.MAINTENANCE },
];
const visitTypeChoicesWithComm = [
    { label: 'visit_type_repair', value: VISIT_TYPES.REPAIR },
    { label: 'visit_type_maintenance', value: VISIT_TYPES.MAINTENANCE },
    { label: 'visit_type_commissioning', value: VISIT_TYPES.COMMISSIONING },
];

const urgencyChoices = [
    { label: 'urgency_normal', value: false },
    { label: 'urgency_urgent', value: true, info: 'urgency_urgent_info' },
];

const tomorrow = (() => {
    const t = new Date();
    t.setDate(new Date().getDate() + 1);
    return t.toISOString().substr(0, 10);
})();

const VisitDetails = () => {
    // State
    const [date, setDate] = useState(useSelector(store => store.form.visitDate));
    const dateErr = useSelector(store => store.errors.visitDate);
    // Store values
    const dispatch = useDispatch();
    const visitType = useSelector(store => store.form.visitType);
    const visitTypeErr = useSelector(store => store.errors.visitType);
    const hasCommissioning = useSelector(renderCommissioning);
    const isUrgent = useSelector(store => store.form.isUrgent);

    const onRequestDaikinAssistance = () => {
        dispatch(setRequestDaikinAssistance());
    };

    return (
        <section className='form-section'>
            <Guard selector={renderServicePartnerEnd}>
                <div className='form-group'>
                    <p>{i18n('request_daikin_assistance_title')}</p>
                    <button type='button' className='btn btn-primary-outline' onClick={onRequestDaikinAssistance}>
                        <Icons.Glyph.Wrench className='icon' />
                        {' '}
                        {i18n('request_daikin_assistance')}
                    </button>
                </div>
            </Guard>
            <Guard selector={renderVisitDetailsPart}>
                <h2 className='form-section-title'>
                    {i18n('request')}
                </h2>
                <RadioChoice
                    id='visit_type'
                    label='visit_type'
                    choices={hasCommissioning ? visitTypeChoicesWithComm : visitTypeChoices}
                    onChange={value => dispatch(setVisitType(value))}
                    selectedValue={visitType}
                    error={visitTypeErr}
                    required
                />
                <div className='grid-row'>
                    <div className='grid-col-12 grid-col-sm-6'>
                        <div className={`form-group form-fields required ${dateErr ? 'error' : ''}`}>
                            <label className='form-label' htmlFor='date-of-visit'>
                                {i18n('desired_date_of_visit')}
                            </label>
                            <input
                                id='date-of-visit'
                                type='date'
                                min={tomorrow}
                                pattern='\d{2}-\d{2}-\d{4}'
                                value={date}
                                onChange={e => setDate(e.target.value)}
                                onBlur={e => dispatch(setVisitDate(e.target.value))}
                                className='form-control form-date'
                                required
                            />
                            {dateErr && <label className='error-msg'>{dateErr}</label>}
                            {i18n('desired_date_of_visit_helptext') && (
                                <div>
                                    <small className='text-muted'>
                                        {i18n('desired_date_of_visit_helptext', false)}
                                    </small>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Guard selector={renderUrgency}>
                    <RadioChoice
                        id='urgency'
                        label='urgency'
                        choices={urgencyChoices}
                        onChange={value => dispatch(setUrgency(value))}
                        selectedValue={isUrgent}
                        required
                    />
                </Guard>
            </Guard>
        </section>
    );
};

export default () => <Guard selector={renderVisitDetails}><VisitDetails /></Guard>;
