import { useEffect } from 'react';
import { Dispatch } from 'redux';
import actions from '../redux/actions';
import { TOOL_STATE, ToolData } from '../type';

enum EVENTS {
    // INBOUND - Aaltra -> iO
    getToken = 'getToken',
    update = 'update',
    done = 'done',
    // OUTBOUND - iO -> Aaltra
    setToken = 'setToken',
    init = 'init',
    navigate = 'navigate',
    unmount = 'unmount',
}

// eslint-disable-next-line import/prefer-default-export
export const useSchedulerTool = (dispatch: Dispatch): void => {
    // Postal handler
    useEffect(() => {
        const onSchedulerEvent: ICallback<unknown> = (data, envelope) => {
            const [, type] = envelope.topic.split('.');
            switch (type) {
                case EVENTS.getToken:
                    setTimeout(() => {
                        dispatch(actions.scheduler.getTokenSchedulerTool());
                    }, 200);
                    break;
                case EVENTS.update:
                    dispatch(actions.scheduler.updateSchedulerTool(data as ToolData));
                    break;
                case EVENTS.done:
                    dispatch(actions.scheduler.setToolState(TOOL_STATE.done));
                    break;
                default:
                    break;
            }
        };
        window._pwaChannel.subscribe('scheduler.*', onSchedulerEvent);
    }, [dispatch]);
};
