/* eslint-disable jsx-quotes */
import React from 'react';

const CloseButton = ({ onClick }) => {
    return (
        <a
            href=''
            onClick={e => {
                e.preventDefault();
                onClick();
            }}
            className='pull-right'
        >
            <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'>
                <path fill='#09C' fillRule='evenodd' d="M44.7071068,243.292893 L50,248.585 L55.2928932,243.292893 C55.6834175,242.902369 56.3165825,242.902369 56.7071068,243.292893 C57.0976311,243.683418 57.0976311,244.316582 56.7071068,244.707107 L51.415,250 L56.7071068,255.292893 C57.0976311,255.683418 57.0976311,256.316582 56.7071068,256.707107 C56.3165825,257.097631 55.6834175,257.097631 55.2928932,256.707107 L50,251.415 L44.7071068,256.707107 C44.3165825,257.097631 43.6834175,257.097631 43.2928932,256.707107 C42.9023689,256.316582 42.9023689,255.683418 43.2928932,255.292893 L48.585,250 L43.2928932,244.707107 C42.9023689,244.316582 42.9023689,243.683418 43.2928932,243.292893 C43.6834175,242.902369 44.3165825,242.902369 44.7071068,243.292893 Z" transform="translate(-43 -243)" />
            </svg>
        </a>
    );
};

export default CloseButton;
