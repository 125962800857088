import i18n from '@common/i18n';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSerialKnown } from '../actions';
import Guard from '../controls/Guard';
import RadioChoice from '../controls/RadioChoice';
import { forceInstallationViaSerial, renderInstallationForms } from '../selectors';
import InstallationDetailsViaDescription from './InstallationDetailsViaDescription';
import InstallationDetailsViaSerial from './InstallationDetailsViaSerial';
import TechnicalQuestions from './TechnicalQuestions';

const ynChoices = [
    { label: 'yes', value: true },
    { label: 'no', value: false },
];

const InstallationDetails = () => {
    const dispatch = useDispatch();
    const serialKnown = useSelector(store => store.form.installationSerialKnown);
    const serialKnownError = useSelector(store => store.errors.installationSerialKnown);
    const offerChoice = !useSelector(forceInstallationViaSerial);
    return (
        <section className='form-section'>
            <h2 className='form-section-title'>{i18n('installation_details')}</h2>
            {offerChoice && (
                <RadioChoice
                    id='installation_serial_known'
                    label='installation_serial_known'
                    choices={ynChoices}
                    onChange={known => dispatch(setSerialKnown(known))}
                    selectedValue={serialKnown}
                    error={serialKnownError}
                    required
                />
            )}
            {serialKnown === true && <InstallationDetailsViaSerial />}
            {serialKnown === false && <InstallationDetailsViaDescription />}
            <TechnicalQuestions />
        </section>
    );
};

export default () => <Guard selector={renderInstallationForms}><InstallationDetails /></Guard>;
