import i18n from '@common/i18n';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { queryInstallations, selectInstallation, setHasExistingInstallation } from '../actions';
import Guard from '../controls/Guard';
import RadioChoice from '../controls/RadioChoice';
import Typeahead from '../controls/Typeahead';
import {
    createInstallationName,
    lockExistingInstallation,
    renderExistingInstallationEnd,
    renderExistingInstallationPro,
} from '../selectors';

const existingInstallationChoices = [
    { label: 'yes', value: true },
    { label: 'no', value: false },
];

const ExistingInstallationEnd = () => {
    const dispatch = useDispatch();
    // Store data
    const existingInstallation = useSelector(store => store.form.existingInstallation);
    // Fetch installations and map them to valid { id, name } objects
    const installations = useSelector(store => store.settings.installations || []);
    const validInstallations = installations
        .filter(i => !!i.id)
        .map(i => ({ id: i.id, name: createInstallationName(i), productRangeId: i.productRange }));
    // Dispatch selectInstallation when dropdown item was selected
    const onSelectEndUserInstallation = (e) => {
        const id = e.target.value;
        const installation = validInstallations.find(inst => inst.id === id);
        dispatch(selectInstallation(installation));
    };

    return (
        <section className='form-section'>
            <h2 className='form-section-title'>
                {i18n('request_for_assistance')}
            </h2>
            <div className='form-group form-fields required'>
                <label className='form-label' htmlFor='existing-installation'>{i18n('existing_installation')}</label>
                <div className=''>
                    {validInstallations.length > 1 ? (
                        <select
                            id='installation'
                            name='installation'
                            value={existingInstallation?.id}
                            onChange={onSelectEndUserInstallation}
                            className='form-control'
                            required
                        >
                            {validInstallations.map(installation => (
                                <option key={installation.id} value={installation.id}>
                                    {installation.name}
                                </option>
                            ))}
                        </select>
                    ) : (
                        <input
                            type='text'
                            className='form-control'
                            value={existingInstallation?.name}
                            readOnly
                        />
                    )}

                </div>
            </div>
        </section>
    );
};

const ExistingInstallationPro = () => {
    const dispatch = useDispatch();
    // State
    const [query, setQuery] = useState(useSelector(store => store.form.existingInstallation?.name) || '');
    // Store data
    const hasExistingInstallation = useSelector(store => store.form.hasExistingInstallation);
    const suggestions = useSelector(store => store.installations.suggestions);
    const isLocked = useSelector(lockExistingInstallation);
    // Actions
    const onChangeQuery = value => {
        setQuery(value);
        dispatch(queryInstallations(value));
    };
    const onSelectInstallation = suggestion => {
        setQuery(suggestion.name);
        dispatch(selectInstallation(suggestion));
    };

    return (
        <section className='form-section'>
            <h2 className='form-section-title'>
                {i18n('request_for_assistance')}
            </h2>
            {!isLocked && (
                <RadioChoice
                    id='has_existing_installation'
                    label='has_existing_installation'
                    choices={existingInstallationChoices}
                    onChange={value => dispatch(setHasExistingInstallation(value))}
                    selectedValue={hasExistingInstallation}
                    required
                />
            )}
            {hasExistingInstallation && (
                <div className='form-group form-fields required'>
                    <label className='form-label' htmlFor='existing-installation'>{i18n('existing_installation')}</label>
                    <div className=''>
                        {isLocked ? (
                            <input
                                type='text'
                                className='form-control'
                                value={query}
                                readOnly
                            />
                        ) : (
                            <Typeahead
                                id='existing-installation'
                                value={query}
                                suggestions={suggestions}
                                onChangeQuery={onChangeQuery}
                                onSelectSuggestion={onSelectInstallation}
                            />
                        )}
                    </div>
                </div>
            )}
        </section>
    );
};

export default () => (
    <>
        <Guard selector={renderExistingInstallationPro}><ExistingInstallationPro /></Guard>
        <Guard selector={renderExistingInstallationEnd}><ExistingInstallationEnd /></Guard>
    </>
);
