import i18n from '@common/i18n';
import React from 'react';

const Check = ({ id, label, info, value, isSelected, onChange }) => (
    <div className='form-check form-check-inline'>
        <label
            className='form-check-label'
            onClick={() => onChange(value, !isSelected)}
            htmlFor={id}
        >
            <input
                name={id}
                type='checkbox'
                value={value}
                checked={isSelected}
                readOnly
            />
            {` ${i18n(label)}`}
        </label>
        {info && <div><small className='text-muted'>{i18n(info)}</small></div>}
    </div>
);

const CheckboxChoice = ({ id, label, choices, error, onChange, selectedValues, required }) => (
    <div className={`form-group form-fields${required ? ' required' : ''}`}>
        <label className='form-label'>{i18n(label)}</label>
        <div className={`form-fieldset ${error ? 'error' : ''}`}>
            {choices.map(choice => {
                const choiceId = `${id}-${choice.value.toString()}`;
                return (
                    <Check
                        id={choiceId}
                        key={choiceId}
                        label={choice.label}
                        info={choice.info}
                        value={choice.value}
                        isSelected={selectedValues.includes(choice.value)}
                        onChange={(value, selected) => {
                            // Either add or remove it from the selectedValues array
                            onChange(selected ? [...selectedValues, value] : selectedValues.filter(c => c !== value));
                        }}
                    />
                );
            })}
        </div>
        {error && <label className='error-msg'>{error}</label>}
    </div>
);

export default CheckboxChoice;
