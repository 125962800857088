import { ACTIONS } from '../actions';

const defaultState = [];

const productRangesReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SUGGEST_PRODUCTRANGES:
            return action.results || defaultState;
        default:
            return state || defaultState;
    }
};

export default productRangesReducer;
