import { ACTIONS } from "../actions";

export const SESSION_USER_TYPES = {
    ANON: 'anonymous',
    PRO: 'professional',
    END: 'end-user',
};

export const defaultState = {
    urls: {},
    country: null,
    userType: null,
    installation: null,
    isSubmitting: false,
    impersonation: false,
};

const settingsReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SUBMIT:
            return { ...state, isSubmitting: true };
        case ACTIONS.SET_ERRORS:
            return { ...state, isSubmitting: false };
        default:
            return state || defaultState;
    }
};

export default settingsReducer;
