import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

type ContainerProps = { ariaBusy: string };
const Container = styled.div<ContainerProps>`
    width: 100%;
    height: 100%;
    padding: 5% 0;
    text-align: center;
    background-color: #fff;
    border-radius: 0.4rem;
`;

const rotateKeyframes = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

const Image = styled.img`
    width: 5rem;
    height: 5rem;
    -webkit-animation: ${rotateKeyframes} 1s linear infinite;
    animation: ${rotateKeyframes} 1s linear infinite;
    border-radius: 9rem;
    vertical-align: middle;
    text-align: center;
`;

const SmallImage = styled.img`
    width: 2rem;
    height: 2rem;
    -webkit-animation: ${rotateKeyframes} 1s linear infinite;
    animation: ${rotateKeyframes} 1s linear infinite;
    border-radius: 9rem;
    vertical-align: middle;
    text-align: center;
`;

const Message = styled.p`
    margin: 0;
    margin-top: 2rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    color: #a1b6bd;
    line-height: 2.6rem;
`;

type PropsType = {
    message?: string;
};

const Loader: FC<PropsType> = ({ message }) => (
    <Container role="progressbar" ariaBusy="true">
        <Image src="/assets/hsn/imgs/preloader.png" alt="" />
        {message && <Message className="preloader-text">{message}</Message>}
    </Container>
);

export const SmallLoader = (): JSX.Element => (
    <SmallImage src="/assets/hsn/imgs/preloader.png" alt="" />
);

export default Loader;
