import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { queryServicePartners, selectServicePartner, setServicePartnerKnown, setServiceByDaikin } from '../actions';
import Guard from '../controls/Guard';
import RadioChoice from '../controls/RadioChoice';
import Typeahead from '../controls/Typeahead';
import {
    renderServicePartner,
    renderServicePartnerEnd,
    getServicePartnerDetails,
    getInstallationServicePartner,
} from '../selectors';
import SpContactCard from '../controls/SpContactCard';
import InputWrapper from '../controls/InputWrapper';

const servicePartnerKnownChoices = [
    { label: 'yes', value: true },
    { label: 'no', value: false },
];

const serviceByDaikinChoices = [
    { label: 'intervention_by_service_partner', value: false },
    { label: 'intervention_by_daikin', value: true },
];

const ServicePartnerEnd = () => {
    // Store data
    const servicePartner = useSelector(getInstallationServicePartner);
    const details = servicePartner ? {
        companyName: servicePartner.companyName,
        phoneNumber: servicePartner.phoneNumber,
        emailAddress: servicePartner.email,
    } : null;
    const isLoading = !!servicePartner && !details;

    return (
        <SpContactCard title='contact_service_partner' details={details} isLoading={isLoading} />
    );
};

const ServicePartner = () => {
    const dispatch = useDispatch();
    // State
    const [query, setQuery] = useState(useSelector(store => store.form.servicePartner?.name) || '');
    // Store data
    const servicePartnerKnown = useSelector(store => store.form.servicePartnerKnown);
    const serviceByDaikin = useSelector(store => store.form.serviceByDaikin);
    const suggestions = useSelector(store => store.servicePartner.suggestions);
    const isLoading = useSelector(store => store.servicePartner.isLoading);
    const details = useSelector(getServicePartnerDetails);
    const servicePartnerError = useSelector(store => store.errors.servicePartner);
    // Actions
    const onChangeServicePartnerQuery = value => {
        setQuery(value);
        dispatch(queryServicePartners(value));
    };
    const onSelectServicePartner = suggestion => {
        setQuery(suggestion.name);
        dispatch(selectServicePartner(suggestion));
    };
    const onFocusServicePartner = () => dispatch(queryServicePartners(query));

    return (
        <>
            <RadioChoice
                id='service_partner_known'
                label='service_partner_known'
                choices={servicePartnerKnownChoices}
                onChange={value => dispatch(setServicePartnerKnown(value))}
                selectedValue={servicePartnerKnown}
                required
            />
            {servicePartnerKnown && (
                <>
                    <InputWrapper
                        id='service-partner'
                        label='your_service_partner'
                        error={servicePartnerError}
                        required
                    >
                        <Typeahead
                            value={query}
                            suggestions={suggestions}
                            onFocus={onFocusServicePartner}
                            onChangeQuery={onChangeServicePartnerQuery}
                            onSelectSuggestion={onSelectServicePartner}
                        />
                    </InputWrapper>
                    <RadioChoice
                        id='intervention_by'
                        label='intervention_by'
                        choices={serviceByDaikinChoices}
                        onChange={value => dispatch(setServiceByDaikin(value))}
                        selectedValue={serviceByDaikin}
                        required
                    />
                    {serviceByDaikin === false && (
                        <SpContactCard title='contact_service_partner' details={details} isLoading={isLoading} />
                    )}
                </>
            )}
        </>
    );
};

export default () => (
    <>
        <Guard selector={renderServicePartner}><ServicePartner /></Guard>
        <Guard selector={renderServicePartnerEnd}><ServicePartnerEnd /></Guard>
    </>
);
