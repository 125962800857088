import { ACTIONS } from '../actions';

const defaultState = {
    suggestions: [],
    details: {},
};

const installationSuggestionsReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SUGGEST_INSTALLATIONS:
            // Suggest a couple of installations
            return { ...state, suggestions: action.results || defaultState.suggestions };
        case ACTIONS.SELECT_INSTALLATION:
            // Installation was selected, clear out suggestions
            return { ...state, suggestions: defaultState.suggestions };
        default:
            return state || defaultState;
    }
};

export default installationSuggestionsReducer;
