import i18n from '@common/i18n';
import React from 'react';
import CloseButton from './CloseButton';

const CloseablePanel = ({ title, children, onClose }) => (
    <section className='panel'>
        <div className='panel-header'>
            <label>{i18n(title)}</label>
            <CloseButton className='pull-right' onClick={onClose} />
        </div>
        {children}
    </section>
);

export default CloseablePanel;
