import { useEffect, useReducer, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useDebounce } from '@common/hooks';

// Tiny reducer for easier onChange-handlers + shouldComponentUpdate
const mergeReducer = (state, action) => {
    // If nothing changed, just return the existing state (equal by reference)
    const hasChanges = Object.entries(action).some(([key, value]) => state[key] !== value);
    return hasChanges ? { ...state, ...action } : state;
};

// When typing in forms redux is spamming actions for every single keypress
// This hook debounces the change-action by 1.4s and only dispatches actual changes
// Requirements:
// The store value is of type Object. This is not supposed to be used for primitives
export const useDelayedFormChangeDispatch = (stateFromProps, onChange) => {
    const [state, setState] = useReducer(mergeReducer, {});
    // Convert props to state
    useEffect(() => { setState(stateFromProps); }, [stateFromProps]);
    // Debounced onChange so redux-actions aren't spammed on every keypress
    const debouncedState = useDebounce(state, 1400);
    useEffect(() => {
        // Call onChange if something actually changed
        if (Object.keys(state).length && !shallowEqual(stateFromProps, state)) onChange(debouncedState);
    }, [debouncedState]);
    // Return like a regular useState
    return [state, setState];
};

export const useDelayedPrimitiveChangeDispatch = (stateFromProps, onChange) => {
    const [state, setState] = useState();
    // Convert props to state
    useEffect(() => { setState(stateFromProps); }, [stateFromProps]);
    // Debounced onChange so redux-actions aren't spammed on every keypress
    const debouncedState = useDebounce(state, 1400);
    useEffect(() => {
        if (state !== undefined && state !== stateFromProps) onChange(debouncedState);
    }, [debouncedState]);
    // Return like a regular useState
    return [state, setState];
};
