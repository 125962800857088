import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'element-closest-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import postal from 'postal';
import { applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from 'redux';
import { connect, Provider } from 'react-redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { tryParseBoolean } from '@tsUtils';

const getId = (id) => {
    switch (id) {
        case 'hsn-app':
        case 'hsn-summary-a2a':
            return 'hsn';
        default:
            return id;
    }
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// UTILITY METHODS
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

/* APPLICATION INSTANTIATION */

const appIDs = [];
const getAppID = (element, ID) => {
    let proposal = element.id;
    if (!proposal) {
        // Iterate until we find a unique ID
        const generateAutoID = (i = 1) => {
            const randomID = `${ID}-${i}`;
            return appIDs.includes(randomID) ? generateAutoID(i + 1) : randomID;
        };
        proposal = generateAutoID();
    }
    appIDs.push(proposal);
    return proposal;
};

// Create meta-data for versioning
const __META__ = () => ({ ts: __TIMESTAMP, br: __BRANCH, ch: __COMMIT });

// eslint-disable-next-line default-param-last
const initAppInstances = (App, ID, reducers = () => { }, mapInputValueToState, middleware) => {
    // Check all DOM-element on the page marked as marlon-react-app
    // Concat is needed because getElementsByClassName returns an 'html-collection'
    // Sorting based on ID to prevent automatically generated ID's to duplicate true DOM-IDs
    [...document.getElementsByClassName(ID)]
        .sort((a, b) => a.id < b.id)
        .forEach((ele) => {
            try {
                // Fetch ID or assign random
                const id = getAppID(ele, ID);
                // Fetch data from DOM-element data-attributes
                let { json, awaitevent } = ele.dataset;
                // Try to parse JSON
                if (json) {
                    try {
                        json = JSON.parse(json);
                    } catch (e) {
                        console.warn('Error parsing data-json attribute:', e);
                        json = {};
                    }
                } else {
                    json = {};
                }
                // Create placeholder Objects
                json.data = json.data || {};
                json.settings = json.settings || {};
                // Map predefined state from hidden inputField to store
                let inputName = null,
                    inputValue = null;
                // Check if target DOM-element has an <input/> child node
                if (ele.hasChildNodes()) {
                    const nodes = [...ele.childNodes].filter(
                        (node) => node.nodeType === 1 && node.tagName === 'INPUT',
                    );
                    if (nodes.length === 1) {
                        // Only 1 input-node
                        inputName = nodes[0].getAttribute('name');
                        inputValue = nodes[0].getAttribute('value');
                        if (
                            inputValue !== undefined &&
                            inputValue !== null &&
                            mapInputValueToState
                        ) {
                            json = mapInputValueToState(json, inputValue);
                        }
                    } else {
                        // Map multiple input-nodes to a single data object to send to mapInputValueToState
                        const inputData = {};
                        nodes.forEach((node) => {
                            inputData[node.getAttribute('name')] = node.getAttribute('value');
                        });
                        if (mapInputValueToState) json = mapInputValueToState(json, inputData);
                    }
                } else if (mapInputValueToState) {
                    // Allow the app-root to tailor the initial data to the reducers
                    json = mapInputValueToState(json);
                }
                if (json.settings) {
                    json.settings.id = id;
                    json.settings.outputName = inputName || json.settings.outputName || id;
                    json.settings.outputNode =
                        document.getElementById(json.settings.outputSelector || null) ||
                        document.querySelector(json.settings.outputSelector || null) ||
                        ele.closest('form') ||
                        ele.parentNode;
                }
                // Make sure awaitevent is validated correctly
                awaitevent = awaitevent && awaitevent.toString().toLowerCase() === 'true';
                if (awaitevent && !ele.id) {
                    console.warn(
                        `Application ${id} flagged as awaitevent but is missing a predefined ID.`,
                    );
                }
                // All apps may connect to the devtools except RSN & HSN (more to be added later)
                const sessionDebug =
                    !['rsn', 'hsn'].includes(getId(ID)) ||
                    tryParseBoolean(window.sessionStorage.getItem(`daikin:${getId(ID)}:debug`));
                const isDebugMode = __DEBUG || sessionDebug;
                // Create Redux store
                let composeEnhancers = compose;
                if (isDebugMode) {
                    composeEnhancers = composeWithDevTools({
                        name: id,
                        stateSanitizer: (state) => {
                            const sanitized = { ...state };
                            if (state.settings?.outputNode) {
                                sanitized.settings = {
                                    ...state.settings,
                                    outputNode: '<<DOM_NODE>>',
                                };
                            }
                            if (state.data?.translations && state.data?.filteredTranslations) {
                                sanitized.data = {
                                    ...state.data,
                                    translations: '<<TRANSLATIONS>>',
                                    filteredTranslations: '<<FILTERED_TRANSLATIONS>>',
                                };
                            }
                            return sanitized;
                        },
                        actionSanitizer: (action) => {
                            const sanitized = { ...action };
                            if (action.type === '🛬/setLead/local') {
                                sanitized.lead = '<<LEAD>>';
                            }
                            return sanitized;
                        },
                    });
                }
                const hasMiddleware = middleware && Object.values(middleware).length > 0;
                const store = createStore(
                    combineReducers({ ...reducers, __META__ }),
                    json,
                    hasMiddleware
                        ? composeEnhancers(applyMiddleware(...middleware))
                        : composeEnhancers(),
                );
                // Expose a dispatch method on window object
                window.__BOILER__ = window.__BOILER__ || {};
                window.__BOILER__[id] = (action) => store.dispatch(action);
                // Render method
                const root = createRoot(ele);
                const render = () => {
                    root.render(
                        <Provider store={store}>
                            <App />
                        </Provider>
                    );
                };
                // Check if app should auto-initialize. If not: wait for an <identifier>-init event
                if (awaitevent) {
                    document.addEventListener(`${id}-init`, render);
                } else {
                    render();
                };
            } catch (e) {
                console.warn('Error parsing marlon-react-app');
                console.error(e);
            }
        });
};

/* CONNECT WRAPPER */

const connectWrapper = (mapStateToProps, actions = {}) => {
    return connect(
        // MapStateToProps
        mapStateToProps,
        // MapDispatchToProps
        (dispatch) =>
            Object.entries(actions).reduce(
                (map, [name, action]) => ({
                    ...map,
                    [name]: (...params) => dispatch(action(...params)),
                }),
                {},
            ),
    );
};

/* OUTPUT */

const mapStateToOutputProps = ({ settings }) => ({
    name: settings.outputName,
    node: settings.outputNode,
});

const Output = ({ name, node, value, visible }) => {
    return ReactDOM.createPortal(
        <React.Fragment>
            <input type="hidden" name={name} value={JSON.stringify(value)} />
            {visible && <pre>{`${name}:\n${JSON.stringify(value, null, 2)}`}</pre>}
        </React.Fragment>,
        node,
    );
};

/* POSTAL CHANNEL */

// Create postal channel for communications if it doesn't exist yet
if (!window.__BOILER_CHANNEL__) {
    window.__BOILER_CHANNEL__ = postal.channel('marlon-boiler-postal-channel');
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// EXPORTS
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export default {
    initAppInstances,
    connect: connectWrapper,
    postalChannel: window.__BOILER_CHANNEL__,
    Output: connect(mapStateToOutputProps)(Output),
};
