import { useCallback, useEffect, useState } from 'react';

// Hook - https://usehooks.com/useOnClickOutside/
export const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
        const listener = event => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) return;
            handler(event);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.

};

// Simulates standard react this.forceUpdate() behaviour. Useage:
// > import { useForceUpdate } from '@common/hooks';
// > const forceUpdate = useForceUpdate();
// > forceUpdate();
export const useForceUpdate = () => {
    const [value, setValue] = useState(0); // eslint-disable-line no-unused-vars
    return () => setValue(v => ++v);
};

// Cfr.: https://usehooks.com/useDebounce/
export const useDebounce = (value, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay], // Only re-call effect if value or delay changes
    );
    return debouncedValue;
};

// Returns a callback to pass to an <input /> ref-prop
// autofocussing the input-element on mount
export const useAutoFocus = () => useCallback(node => node?.focus(), []);

// Triggers a callback when a button was pressed
const useKey = (keyEvent, buttonKey, callback, preventDefault, dependencies) => {
    useEffect(() => {
        const onKeyEvent = (e) => {
            if (e.key === buttonKey) {
                if (preventDefault) e.preventDefault();
                callback();
            }
        };
        document.addEventListener(keyEvent, onKeyEvent);
        return () => { document.removeEventListener(keyEvent, onKeyEvent); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
};

export const useKeyUp = (buttonKey, callback, preventDefault = false, dependencies = []) => {
    useKey('keyup', buttonKey, callback, preventDefault, dependencies);
};

export const useKeyDown = (buttonKey, callback, preventDefault = false, dependencies = []) => {
    useKey('keydown', buttonKey, callback, preventDefault, dependencies);
};
