import { ACTIONS } from '../actions';

const defaultState = {
    suggestions: [],
    details: {},
    isLoading: false,
};

const servicePartnerSuggestionsReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.QUERY_SERVICEPARTNERS:
            // Wipe sp-details when performing a new query
            return {
                ...state,
                details: {},
            };
        case ACTIONS.SUGGEST_SERVICEPARTNERS:
            // Suggest a couple of service-partners
            return {
                ...state,
                suggestions: action.results || defaultState.suggestions,
            };
        case ACTIONS.SELECT_SERVICEPARTNER:
            // Service partner was selected, clear out suggestions
            return {
                ...state,
                suggestions: defaultState.suggestions,
                isLoading: true,
            };
        case ACTIONS.ADD_SERVICEPARTNER_DETAILS:
            // Add details to the service-partner "cache"
            return {
                ...state,
                details: {
                    ...state.details,
                    [action.id]: action.details,
                },
                isLoading: false,
            };
        case ACTIONS.SET_SERVICE_PARTNER_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        default:
            return state || defaultState;
    }
};

export default servicePartnerSuggestionsReducer;
