import { createLogic } from 'redux-logic';
import { ACTIONS } from '../actions';

// Compares 2 objects. Only retains objA values that are different than objB
const onlyKeepChanges = (objA, objB) => (
    Object.entries(objA).reduce((diff, [k, v]) => ((v !== objB[k]) ? { ...diff, [k]: v } : diff), {})
);

const trimUserDetailsActions = createLogic({
    type: ACTIONS.SET_USER_DETAILS,
    transform({ getState, action }, next) {
        next({ type: action.type, data: onlyKeepChanges(action.data, getState().form.userDetails) });
    },
});

const trimUserAddressActions = createLogic({
    type: ACTIONS.SET_USER_ADDRESS,
    transform({ getState, action }, next) {
        next({ type: action.type, data: onlyKeepChanges(action.data, getState().form.userAddress) });
    },
});

const trimContactPersonDetailsActions = createLogic({
    type: ACTIONS.SET_CONTACT_PERSON_DETAILS,
    transform({ getState, action }, next) {
        next({ type: action.type, data: onlyKeepChanges(action.data, getState().form.contactPersonDetails) });
    },
});

const trimInstallationAddressActions = createLogic({
    type: ACTIONS.SET_INSTALLATION_ADDRESS,
    transform({ getState, action }, next) {
        next({ type: action.type, data: onlyKeepChanges(action.data, getState().form.installationAddress) });
    },
});

const trimInstallationKnownActions = createLogic({
    type: ACTIONS.SET_INSTALLATION_VIA_SERIAL,
    transform({ getState, action }, next) {
        next({ type: action.type, data: onlyKeepChanges(action.data, getState().form.installationKnown) });
    },
});

const trimInstallationUnknownActions = createLogic({
    type: ACTIONS.SET_INSTALLATION_VIA_DESCRIPTION,
    transform({ getState, action }, next) {
        next({ type: action.type, data: onlyKeepChanges(action.data, getState().form.installationUnknown) });
    },
});

// These actions always dispatch the entire (eg.:) address-object. Even though the user only changed 1 of the values.
// This logic trims away all the unchanged values from action.data
export default [
    trimUserDetailsActions,
    trimUserAddressActions,
    trimContactPersonDetailsActions,
    trimInstallationAddressActions,
    trimInstallationKnownActions,
    trimInstallationUnknownActions,
];
