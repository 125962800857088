import { TOOL_STATE, ToolData } from '../../type';

export enum TOOL_SCHEDULER_ACTION_TYPES {
    setToolState = 'scheduler.setToolState',
    getToken = 'scheduler/getToken',
    init = 'scheduler/init',
    update = 'scheduler/update',
    navigate = 'scheduler/navigate',
}

export type SetToolStateAction = {
    type: TOOL_SCHEDULER_ACTION_TYPES.setToolState;
    toolState: TOOL_STATE;
};
const setToolState = (toolState: TOOL_STATE): SetToolStateAction => ({
    type: TOOL_SCHEDULER_ACTION_TYPES.setToolState,
    toolState,
});

export type GetTokenSchedulerToolAction = { type: TOOL_SCHEDULER_ACTION_TYPES.getToken };
const getTokenSchedulerTool = (): GetTokenSchedulerToolAction => ({
    type: TOOL_SCHEDULER_ACTION_TYPES.getToken,
});

export type InitSchedulerToolAction = { type: TOOL_SCHEDULER_ACTION_TYPES.init };
const initSchedulerTool = (): InitSchedulerToolAction => ({
    type: TOOL_SCHEDULER_ACTION_TYPES.init,
});

export type UpdateSchedulerToolAction = {
    type: TOOL_SCHEDULER_ACTION_TYPES.update;
    data: ToolData;
};
const updateSchedulerTool = (data: ToolData): UpdateSchedulerToolAction => ({
    type: TOOL_SCHEDULER_ACTION_TYPES.update,
    data,
});

type NavigateSchedulerToolAction = { type: TOOL_SCHEDULER_ACTION_TYPES.navigate };
const navigateSchedulerTool = (): NavigateSchedulerToolAction => ({
    type: TOOL_SCHEDULER_ACTION_TYPES.navigate,
});

export type SchedulerActionsType =
    | SetToolStateAction
    | GetTokenSchedulerToolAction
    | InitSchedulerToolAction
    | UpdateSchedulerToolAction
    | NavigateSchedulerToolAction;

export default {
    setToolState,
    getTokenSchedulerTool,
    initSchedulerTool,
    updateSchedulerTool,
    navigateSchedulerTool,
};
