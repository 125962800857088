/* eslint-disable func-names */
import boiler from '@boiler';
import i18n from '@common/i18n';
import { createLogicMiddleware } from 'redux-logic';
import App from './App';
import schedulerLogic from './redux/logic/schedulerLogic';
import reducers from './redux/reducers';

const mapInputToState = (input) => {
    const { settings, appState, labels } = input;
    i18n.set(labels);
    return { settings, appState };
};

(function () {
    boiler.initAppInstances(App, 'sbm-scheduler', reducers, mapInputToState, [
        createLogicMiddleware(schedulerLogic),
    ]);
})();
