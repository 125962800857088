import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ExistingInstallation from './components/ExistingInstallation';
import UserType from './components/UserType';
import ServicePartner from './components/ServicePartner';
import VisitDetails from './components/VisitDetails';
import HasSbmAccount from './components/HasSbmAccount';
import UserDetails from './components/UserDetails';
import Contact from './components/Contact';
import Comment from './components/Comment';
import InstallationAddress from './components/InstallationAddress';
import InstallationDetails from './components/InstallationDetails';
import Legal from './components/Legal';
import Submit from './components/Submit';
import { fetchPricingInfo } from './actions';

const App = () => {
    const dispatch = useDispatch();
    const productRange = useSelector(store => store.settings.installation?.productRange);

    useEffect(() => {
        // Fetch pricing info when starting from an existing installation, starting from Installation database tab
        if (productRange) dispatch(fetchPricingInfo());
    }, []);

    return (
        <form>
            <ExistingInstallation />
            <HasSbmAccount />
            <UserType />
            <ServicePartner />
            <VisitDetails />
            <UserDetails />
            <Contact />
            <Comment />
            <InstallationAddress />
            <InstallationDetails />
            <Legal />
            <Submit />
        </form>
    );
};

export default App;
