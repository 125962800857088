import { ACTIONS } from '../actions';

const defaultState = {
    // ExistingInstallation.js
    hasExistingInstallation: null,
    existingInstallation: null,
    // VisitDetails.js
    visitType: null,
    visitDate: null,
    // ServicePartner.js
    servicePartner: null,
    // UserDetails.js
    userDetails: {},
    userAddress: {},
    // Contact.js
    contactType: null,
    contactPersonDetails: {},
    // InstallationAddress.js
    isSameAddress: null,
    installationAddress: {},
    // InstallationDetails.js
    installationSerialKnown: null,
    installationKnown: {
        material: null,
        serial: null,
        productRange: null,
    },
    installationUnknown: {
        energySources: null,
        heatingWithWater: null,
        hasDomesticHotWater: null,
    },
    technicalQuestions: {},
    // Legal.js
    legalAgreementAccepted: null,
    pricingInfo: null,
};

// Copies the given objects' keys and sets all values to null
const nullifyObjectValues = obj => Object.fromEntries(Object.keys(obj).map(k => [k, null]));

const formReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_ERRORS:
            return {
                ...state,
                ...action.errors,
                userDetails: { ...state.userDetails, ...action.errors.userDetails },
                userAddress: { ...state.userAddress, ...action.errors.userAddress },
                contactPersonDetails: { ...state.contactPersonDetails, ...action.errors.contactPersonDetails },
                installationAddress: { ...state.installationAddress, ...action.errors.installationAddress },
                installationKnown: { ...state.installationKnown, ...action.errors.installationKnown },
                installationUnknown: { ...state.installationUnknown, ...action.errors.installationUnknown },
                technicalQuestions: { ...state.technicalQuestions, ...action.errors.technicalQuestions },
            };
        case ACTIONS.SET_HAS_EXISTING_INSTALLATION:
            return { ...state, hasExistingInstallation: null };
        case ACTIONS.SELECT_INSTALLATION:
            return { ...state, existingInstallation: null };
        case ACTIONS.SET_USER_TYPE:
            return { ...state, userType: null };
        case ACTIONS.SET_VISIT_DATE:
            return { ...state, visitDate: null };
        case ACTIONS.SET_VISIT_TYPE:
            return { ...state, visitType: null };
        case ACTIONS.QUERY_SERVICEPARTNERS:
        case ACTIONS.SELECT_SERVICEPARTNER:
            return { ...state, servicePartner: null };
        case ACTIONS.SET_USER_DETAILS:
            return { ...state, userDetails: { ...state.userDetails, ...nullifyObjectValues(action.data) } };
        case ACTIONS.SET_USER_ADDRESS:
            return { ...state, userAddress: { ...state.userAddress, ...nullifyObjectValues(action.data) } };
        case ACTIONS.SET_CONTACT_TYPE:
            return { ...state, contactType: null };
        case ACTIONS.SET_CONTACT_PERSON_DETAILS:
            return {
                ...state,
                contactPersonDetails: { ...state.contactPersonDetails, ...nullifyObjectValues(action.data) },
            };
        case ACTIONS.SET_IS_SAME_ADDRESS:
            return { ...state, isSameAddress: null };
        case ACTIONS.SET_INSTALLATION_ADDRESS:
            return {
                ...state,
                installationAddress: { ...state.installationAddress, ...nullifyObjectValues(action.data) },
            };
        case ACTIONS.SET_SERIAL_KNOWN:
            return { ...state, installationSerialKnown: null };
        case ACTIONS.SET_INSTALLATION_VIA_SERIAL:
            return {
                ...state,
                installationKnown: { ...state.installationKnown, ...nullifyObjectValues(action.data) },
            };
        case ACTIONS.SET_INSTALLATION_VIA_DESCRIPTION:
            return {
                ...state,
                installationUnknown: { ...state.installationUnknown, ...nullifyObjectValues(action.data) },
            };
        case ACTIONS.SET_LEGAL_AGREEMENT_ACCEPTED:
            return { ...state, legalAgreementAccepted: null };
        default:
            return state || defaultState;
    }
};

export default formReducer;
