import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserDetails, setUserAddress } from '../actions';
import { renderUserDetails, renderCompanyField } from '../selectors';
import Guard from '../controls/Guard';
import AddressForm from '../controls/AddressForm';
import ContactDetailsForm from '../controls/ContactDetailsForm';

const UserDetails = () => {
    const dispatch = useDispatch();
    const details = useSelector(store => store.form.userDetails);
    const address = useSelector(store => store.form.userAddress);
    const detailsErrors = useSelector(store => store.errors.userDetails);
    const addressErrors = useSelector(store => store.errors.userAddress);
    const hasCompany = useSelector(renderCompanyField);
    return (
        <section className='form-section'>
            <ContactDetailsForm
                id='user-details'
                title='your_details'
                details={details}
                errors={detailsErrors}
                onChange={value => dispatch(setUserDetails(value))}
                hasCompany={hasCompany}
                autoComplete
            />
            <AddressForm
                id='user-address'
                address={address}
                errors={addressErrors}
                onChange={value => dispatch(setUserAddress(value))}
                autoComplete
            />
        </section>
    );
};

export default () => <Guard selector={renderUserDetails}><UserDetails /></Guard>;
