import { ToolData } from '../../type';
import { ActionsType, TOOL_SCHEDULER_ACTION_TYPES } from '../actions';

const initialToolData: ToolData = {
    data: { reservation: null },
    platform: {
        basketId: '',
        installationId: '',
    },
    step: null,
};

const toolData = (state: ToolData, action: ActionsType): ToolData => {
    switch (action.type) {
        case TOOL_SCHEDULER_ACTION_TYPES.update:
            return {
                ...state,
                ...action.data,
            };
        default:
            return { ...initialToolData, ...state };
    }
};

export default toolData;
