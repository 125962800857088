import { MetaSettings } from '@common/types';

type Reservation = {
    reservation_id: string;
    date: string;
    start_time: string;
    end_time: string;
    status: string;
};

export type ToolData = {
    data: { reservation: Reservation | null };
    platform: {
        basketId: string;
        installationId: string;
    };
    step: null | string;
};

export type Settings = MetaSettings & {
    installationDetailUrl?: string | undefined;
    schedulerJsUrl: string;
    schedulerToken: string;
};

export enum TOOL_STATE {
    injected = 'injected',
    done = 'done',
    error = 'error',
}

export type AppState = {
    installationId: string;
    basketId: string | null | undefined;
    history: Array<string | null>;
    toolState: TOOL_STATE | null;
};

export type State = {
    toolData: ToolData;
    settings: Settings;
    appState: AppState;
};
