import axios from 'axios';
import { createLogic } from 'redux-logic';
import FuzzySet from 'fuzzyset.js';
import {
    addServicePartnerDetails,
    suggestServicePartners,
    ACTIONS,
    setServicePartnerLoading,
} from '../actions';

const searchCache = [];
const resultsCache = [];

// Add _new_ results to cache
const addToCache = results => resultsCache.push(...results.filter(res => resultsCache.every(c => c.id !== res.id)));

// Return actual service-partner objects for these 5 names
const getTopFiveFromCache = query => FuzzySet(resultsCache.map(sp => sp.name), false)
    .get(query, [], 0.25) // Get all matching names
    .reduce((arr, match) => ([...arr, ...resultsCache.filter(sp => sp.name === match[1])]), [])
    .slice(0, 5); // Only return the best 5

// Check if query is valid and query can be returned from cache
const validateFetchServicePartnerLogic = createLogic({
    type: ACTIONS.QUERY_SERVICEPARTNERS,

    validate({ getState, action }, allow, reject) {
        const { query } = action;
        if (!query || query.length <= 2) { // Don't make requests for small queries
            reject(getState().servicePartner.suggestions.length ? suggestServicePartners() : null);
        } else if (searchCache.includes(query)) { // We've already searched for this once, rejected!
            reject(suggestServicePartners(getTopFiveFromCache(query)));
        } else {
            allow(action); // -> Advance to fetchServicePartnerLogic
        }
    },
});

// Query is valid and not cached yet, request data from backend
const fetchServicePartnerLogic = createLogic({
    type: ACTIONS.QUERY_SERVICEPARTNERS,
    debounce: 700,
    latest: true,

    process({ getState, action }, dispatch, done) {
        const { query } = action;
        const request = getState().settings.urls.servicePartnerSearch.replace('__query__', query);
        searchCache.push(query);
        axios.get(request)
            .then(res => {
                if (res.status === 200 && res.data) {
                    addToCache(res.data);
                    dispatch(suggestServicePartners(getTopFiveFromCache(query)));
                }
            })
            .catch(() => { /* TODO */ })
            .finally(done);
    },

});

// Fetch service partner details from backend
const fetchServicePartnerDetailsLogic = createLogic({
    type: ACTIONS.SELECT_SERVICEPARTNER,

    process({ getState, action }, dispatch, done) {
        const { id } = action.servicePartner;
        if (getState().servicePartner.details[id]) {
            done();
        } else {
            const request = getState().settings.urls.servicePartnerDetail.replace('__id__', id);
            axios.get(request)
                .then(res => {
                    if (res.status === 200 && res.data) {
                        dispatch(addServicePartnerDetails(id, res.data));
                    }
                })
                .catch(() => { setServicePartnerLoading(false); })
                .finally(done);
        }
    },
});

export default [validateFetchServicePartnerLogic, fetchServicePartnerLogic, fetchServicePartnerDetailsLogic];
