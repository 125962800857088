import React from 'react';

const Typeahead = props => {

    const {
        id,
        suggestions,
        value,
        onSelectSuggestion,
        onChangeQuery,
        onFocus,
    } = props;

    return (
        <div className='form-typeahead'>
            <input
                className='form-control'
                autoComplete='off'
                type='text'
                id={id}
                value={value}
                onChange={e => onChangeQuery(e.target.value)}
                onFocus={onFocus}
            />
            {suggestions.length > 0 && (
                <div className='form-typeahead-results'>
                    {suggestions.map(sugg => (
                        <button
                            key={sugg.id}
                            type='button'
                            onClick={() => onSelectSuggestion(sugg)}
                            className='form-typeahead-result'
                        >
                            {sugg.name}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Typeahead;
