import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTechnicalAnswers } from '../actions';
import { renderTechnicalQuestions } from '../selectors';
import InputWrapper from '../controls/InputWrapper';
import RadioChoice from '../controls/RadioChoice';

const ynChoices = [
    { label: 'yes', value: true },
    { label: 'no', value: false },
];

const TechnicalQuestions = () => {
    const technicalAnswers = useSelector(store => store.form.technicalAnswers);
    const technicalQuestions = useSelector(store => store.settings.technicalQuestions);
    const shouldRender = useSelector(renderTechnicalQuestions);
    const errors = useSelector(store => store.errors.technicalQuestions);
    const dispatch = useDispatch();
    const onChange = changes => dispatch(setTechnicalAnswers(changes));

    if (!shouldRender) return null;

    return technicalQuestions.map(({ id, question, type, addon }) => {
        switch (type) {
            case 'text':
                return (
                    <InputWrapper id={id} key={id} label={question} error={errors[id]} required>
                        <input
                            type='text'
                            id={id}
                            name={question}
                            value={technicalAnswers[id]}
                            className='form-control'
                            onChange={e => onChange({ [id]: e.target.value })}
                            required
                        />
                    </InputWrapper>
                );
            case 'number':
                return (
                    <InputWrapper id={id} key={id} label={question} error={errors[id]} required>
                        <div className='input-group offset-sm-bottom' style={{ maxWidth: 300 }}>
                            <input
                                type='number'
                                step={0.01}
                                id={id}
                                name={question}
                                value={technicalAnswers[id]}
                                className='form-control'
                                onChange={e => onChange({ [id]: +e.target.value })}
                                required
                            />
                            <span className='input-group-addon'>
                                {addon}
                            </span>
                        </div>
                    </InputWrapper>
                );
            case 'boolean':
                return (
                    <RadioChoice
                        id={id}
                        key={id}
                        label={question}
                        choices={ynChoices}
                        onChange={bool => onChange({ [id]: bool })}
                        selectedValue={technicalAnswers[id]}
                        error={errors[id]}
                        required
                    />
                );
            default: return null;
        }
    });
};

export default TechnicalQuestions;
