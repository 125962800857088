import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Guard from '../controls/Guard';
import RadioChoice from '../controls/RadioChoice';
import ContactDetailsForm from '../controls/ContactDetailsForm';
import { renderContact } from '../selectors';
import { setContactType, setContactPersonDetails } from '../actions';
import { CONTACT_TYPES, USER_TYPES } from '../reducers/formReducer';

const myself = { label: 'contact_type_myself', value: CONTACT_TYPES.MYSELF };
const servicePartner = { label: 'contact_type_service_partner', value: CONTACT_TYPES.SERVICE_PARTNER };
const someoneElse = { label: 'contact_type_someone_else', value: CONTACT_TYPES.SOMEONE_ELSE };
const allChoices = [myself, servicePartner, someoneElse];
const limitedChoices = [myself, someoneElse];

// Check if we should also render servicePartner as a possible contact
const contactTypeChoicesSelector = store => {
    const { userType, servicePartnerKnown } = store.form;
    return (userType !== USER_TYPES.PRO && servicePartnerKnown) ? allChoices : limitedChoices;
};

const Contact = () => {
    const dispatch = useDispatch();
    const contactType = useSelector(store => store.form.contactType);
    const contactPersonDetails = useSelector(store => store.form.contactPersonDetails);
    const contactPersonDetailsErr = useSelector(store => store.errors.contactPersonDetails);
    const contactTypeChoices = useSelector(contactTypeChoicesSelector);
    return (
        <section className='form-section'>
            <RadioChoice
                id='contact_person'
                label='contact_person'
                choices={contactTypeChoices}
                onChange={value => dispatch(setContactType(value))}
                selectedValue={contactType}
                required
            />
            {contactType === CONTACT_TYPES.SOMEONE_ELSE && (
                <ContactDetailsForm
                    id='contact'
                    title='contact_person_details'
                    onChange={details => dispatch(setContactPersonDetails(details))}
                    details={contactPersonDetails}
                    errors={contactPersonDetailsErr}
                    hasLegal
                />
            )}
        </section>
    );
};

export default () => <Guard selector={renderContact}><Contact /></Guard>;
