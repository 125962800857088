import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setInstallationDetailsViaDescription } from '../actions';
import CheckboxChoice from '../controls/CheckboxChoice';
import RadioChoice from '../controls/RadioChoice';

const energySourceChoices = [
    { label: 'gas', value: 'gas' },
    { label: 'oil', value: 'oil' },
    { label: 'electricity', value: 'electricity' },
];

const heatingWithWaterChoices = [
    { label: 'yes', value: true },
    { label: 'no', value: false },
];

const hasDomesticHotWaterChoices = [
    { label: 'yes', value: 1 },
    { label: 'no', value: 0 },
    { label: 'unknown', value: 2 },
];

const InstallationDetailsViaDescription = () => {
    const {
        energySources,
        heatingWithWater,
        hasDomesticHotWater,
    } = useSelector(store => store.form.installationUnknown);
    const errors = useSelector(store => store.errors.installationUnknown);
    const dispatch = useDispatch();
    const onChange = changes => dispatch(setInstallationDetailsViaDescription(changes));
    return (
        <>
            <CheckboxChoice
                id='installation_description_energysources'
                label='installation_description_energysources'
                choices={energySourceChoices}
                onChange={value => onChange({ energySources: value })}
                selectedValues={energySources}
                error={errors.energySources}
                required
            />
            <RadioChoice
                id='installation_description_heating_with_water'
                label='installation_description_heating_with_water'
                subLabel='installation_description_heating_with_water_help'
                choices={heatingWithWaterChoices}
                onChange={value => onChange({ heatingWithWater: value })}
                selectedValue={heatingWithWater}
                error={errors.heatingWithWater}
                required
            />
            <RadioChoice
                id='installation_description_has_dhw'
                label='installation_description_has_dhw'
                choices={hasDomesticHotWaterChoices}
                onChange={value => onChange({ hasDomesticHotWater: value })}
                selectedValue={hasDomesticHotWater}
                error={errors.hasDomesticHotWater}
                required
            />
        </>
    );
};

export default InstallationDetailsViaDescription;
