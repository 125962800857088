import { createLogic } from 'redux-logic';
import actions, { GetTokenSchedulerToolAction, TOOL_SCHEDULER_ACTION_TYPES } from '../actions';
import { State, TOOL_STATE } from '../../type';

type DepObj = { getState: () => State };

type GetTokenDepObj = DepObj & {
    action: GetTokenSchedulerToolAction;
};

const validateGetTokenSchedulerLogic = createLogic({
    type: TOOL_SCHEDULER_ACTION_TYPES.getToken,
    name: 'schedulerLogic.validateGetToken',
    validate({ getState, action }: GetTokenDepObj, allow, reject) {
        const { toolState } = getState().appState;
        if (toolState === TOOL_STATE.injected) {
            allow(action);
        } else if (toolState === TOOL_STATE.error) {
            // Abort early
            reject({ ...action, type: `🚫 (${action.type})` });
        }
    },
});

const setTokenSchedulerLogic = createLogic({
    type: TOOL_SCHEDULER_ACTION_TYPES.getToken,
    name: 'schedulerLogic.setToken',
    process({ getState }: GetTokenDepObj, dispatch, done) {
        const { schedulerToken } = getState().settings;
        // Set token to authenticate with the API.
        window._pwaChannel.publish('scheduler.setToken', { token: schedulerToken });
        dispatch(actions.scheduler.initSchedulerTool());
        done();
    },
});

const initSchedulerLogic = createLogic({
    type: TOOL_SCHEDULER_ACTION_TYPES.init,
    name: 'schedulerLogic.init',
    process({ getState }: DepObj, _dispatch, done) {
        const state = getState();
        const { installationId, basketId } = state.appState;
        const { reservation } = state.toolData.data;
        window._pwaChannel.publish('scheduler.init', {
            target: '#external-tool-sbm-scheduler',
            platform: {
                installationId,
                basketId, // optional
            },
            data: {
                reservation: reservation || null,
            },
            step: null,
        });
        done();
    },
});

const navigateSchedulerLogic = createLogic({
    type: TOOL_SCHEDULER_ACTION_TYPES.navigate,
    name: 'schedulerLogic.navigate',
    process({ getState }: DepObj, _dispatch, done) {
        const { toolData, appState } = getState();
        const {
            platform: { installationId, basketId },
        } = toolData;
        const previousStep = appState.history[appState.history.length - 1];
        window._pwaChannel.publish('scheduler.navigate', {
            platform: {
                installationId,
                basketId,
            },
            data: { reservation: null }, // Clear reservation data when navigating back
            step: previousStep,
        });
        done();
    },
});

const unmountSchedulerLogic = createLogic({
    type: TOOL_SCHEDULER_ACTION_TYPES.setToolState,
    name: 'schedulerLogic.unmount',
    process({ getState }: DepObj, _dispatch, done) {
        const state = getState();
        if (state.appState.toolState === TOOL_STATE.done) {
            const { installationDetailUrl } = state.settings;
            window._pwaChannel.publish('scheduler.unmount');
            if (installationDetailUrl) window.location.href = installationDetailUrl;
        }
        done();
    },
});

export default [
    validateGetTokenSchedulerLogic,
    setTokenSchedulerLogic,
    initSchedulerLogic,
    navigateSchedulerLogic,
    unmountSchedulerLogic,
];
