import { createLogic } from 'redux-logic';
import { ACTIONS, setVisitType, setContactType } from '../actions';
import { CONTACT_TYPES, USER_TYPES, VISIT_TYPES } from '../reducers/formReducer';

// Non-pro users can not do comissioning. Reset if this is the case!
const visitTypeLogic = createLogic({
    type: ACTIONS.SET_USER_TYPE,

    process({ getState, action }, dispatch, done) {
        if (action.userType !== USER_TYPES.PRO && getState().form.visitType === VISIT_TYPES.COMMISSIONING) {
            dispatch(setVisitType(null));
        }
        done();
    },
});

// Professional users can not have service-partner as possible contact. Reset to default 'myself'
const contactTypeLogic = createLogic({
    type: ACTIONS.SET_USER_TYPE,

    process({ getState, action }, dispatch, done) {
        if (action.userType === USER_TYPES.PRO && getState().form.contactType === CONTACT_TYPES.SERVICE_PARTNER) {
            dispatch(setContactType(CONTACT_TYPES.MYSELF));
        }
        done();
    },
});

export default [visitTypeLogic, contactTypeLogic];
