import i18n from '@common/i18n';
import { CONTACT_TYPES, USER_TYPES, VISIT_TYPES } from '../reducers/formReducer';
import { SESSION_USER_TYPES } from '../reducers/settingsReducer';
import { renderTechnicalQuestions } from './renderGuards';

export * from './renderGuards';

export const forceInstallationViaSerial = (store) => ['DE', 'FR'].includes(store.settings.country);

export const getServicePartnerDetails = (store) => store.servicePartner.details[store.form.servicePartner?.id];

// Get service partner on End-user portal
export const getInstallationServicePartner = (store) => {
    const id = store.form.existingInstallation?.id;
    // Get service partner from installed product on End-user portal
    if (store.settings.installation) return store.settings.installation.servicePartner;
    // Get service partner from selected installation on End-user portal
    if (store.settings.installations) {
        return store.settings.installations.find((installation) => installation?.id === id)?.servicePartner;
    }
};

export const getSubmitJson = (store) => {
    const {
        existingInstallation,
        userType,
        servicePartnerKnown,
        servicePartner,
        visitType,
        visitDate,
        isUrgent,
        userDetails,
        userAddress,
        contactType,
        contactPersonDetails,
        comment,
        isSameAddress,
        installationAddress,
        installationSerialKnown,
        installationKnown,
        installationUnknown,
        technicalAnswers,
        legalAgreementAccepted,
    } = store.form;
    const { country, technicalQuestions } = store.settings;
    const isProPortal = store.settings.userType === SESSION_USER_TYPES.PRO;

    const json = {};

    const isBool = (value) => typeof value === 'boolean';

    const normalizeAddress = (address) => ({
        street: address.street,
        number: address.number,
        postalCode: address.postal,
        city: address.city,
        poBox: address.poBox,
        country,
    });
    const normalizeContact = (details) => {
        const contact = {
            firstName: details.firstName,
            lastName: details.lastName,
            phoneNumber: details.phone,
            emailAddress: details.email,
            legal1: details.legal1,
            legal2: details.legal2,
        };
        if (userType !== USER_TYPES.END && details.company) contact.company = details.company;
        return contact;
    };

    // ExistingInstallation
    if (existingInstallation) json.installationId = existingInstallation.id;
    // UserType
    if (userType) json.requesterType = userType;
    // ServicePartner
    if (isBool(servicePartnerKnown)) {
        json.isServicePartnerKnown = servicePartnerKnown;
        if (servicePartnerKnown && servicePartner) {
            json.servicePartner = servicePartner.id;
        }
    }
    // VisitDetails
    if (visitType) json.visitType = visitType;
    if (visitDate) json.desiredDate = visitDate;
    if (!(isProPortal && visitType === VISIT_TYPES.MAINTENANCE)) json.isUrgent = isUrgent;
    // UserDetails
    json.customerDetails = {
        ...normalizeContact(userDetails),
        address: normalizeAddress(userAddress),
    };
    // Contact
    if (contactType) json.whoToContact = contactType;
    if (contactType === CONTACT_TYPES.SOMEONE_ELSE)
        json.contact = normalizeContact(contactPersonDetails);
    // Comment
    if (comment) json.comment = comment;
    // InstallationAddress
    if (isProPortal) {
        json.installationAddress = normalizeAddress(installationAddress);
    } else if (isBool(isSameAddress)) {
        json.installationAddressSameAsContact = isSameAddress;
        if (!isSameAddress) {
            json.installationAddress = normalizeAddress(installationAddress);
        }
    }
    // InstallationDetails
    if (isBool(installationSerialKnown)) {
        json.unitKnown = installationSerialKnown;
        if (installationSerialKnown && installationKnown) {
            // Via serial
            const { material, serial, productRange } = installationKnown;
            json.serial = serial;
            json.material = material;
            json.productRange = productRange;
        } else if (!installationSerialKnown && installationUnknown) {
            // Via description
            const { energySources, heatingWithWater, hasDomesticHotWater } = installationUnknown;
            json.energySources = energySources;
            json.warmWaterForHeating = heatingWithWater;
            json.provideDomesticHotWater = hasDomesticHotWater;
        }
        if (renderTechnicalQuestions(store)) {
            json.technicalQuestions = {
                // Make sure to send { uuid: null } values for all unanswered questions
                ...Object.fromEntries(technicalQuestions.map((question) => [question.id, null])),
                ...technicalAnswers,
            };
        }
    }
    // Legal
    if (isBool(legalAgreementAccepted)) json.termsAgree = legalAgreementAccepted;

    return json;
};

// unitKnown: "This value should not be null."
// installationAddressSameAsContact: "This value should not be null."

const normalizeAddressErrors = (key, res) => ({
    street: res[`${key}[street]`] || null,
    number: res[`${key}[number]`] || null,
    postal: res[`${key}[postalCode]`] || null,
    city: res[`${key}[city]`] || null,
});

const normalizeContactErrors = (key, res) => ({
    company: res[`${key}[company]`] || null,
    firstName: res[`${key}[firstName]`] || null,
    lastName: res[`${key}[lastName]`] || null,
    phone: res[`${key}[phoneNumber]`] || null,
    email: res[`${key}[emailAddress]`] || null,
    legal1: res[`${key}[legal1]`] || null,
    legal2: res[`${key}[legal2]`] || null,
});

// Normalize { "technicalQuestions[uuid-abc-123]": "error" } to { "uuid-abc-123": "error" }
const normalizeErrors = (key, res) =>
    Object.fromEntries(
        Object.entries(res)
            .filter(([id]) => id.startsWith(key))
            .map(([id, value]) => [id.match(/\[(.*?)\]/)?.pop(), value]),
    );

export const parseErrors = (res) => {
    // Handle customer_is_known error
    if (Object.values(res).includes('customer_is_known')) res.serial = i18n('customer_is_known');
    const errors = {
        // VisitDetails
        visitType: res.visitType,
        visitDate: res.desiredDate,
        // ServicePartner
        servicePartner: res.servicePartner,
        // UserDetails
        userDetails: normalizeContactErrors('customerDetails', res),
        userAddress: normalizeAddressErrors('customerDetails[address]', res),
        // Contact
        contactType: res.whoToContact,
        contactPersonDetails: normalizeContactErrors('contact', res),
        // InstallationAddress
        isSameAddress: res.installationAddressSameAsContact,
        installationAddress: normalizeAddressErrors('installationAddress', res),
        // InstallationDetails
        installationSerialKnown: res.unitKnown,
        installationKnown: {
            material: res.material,
            serial: res.serial,
            productRange: res.productRange,
        },
        installationUnknown: {
            energySources: res.energySources,
            heatingWithWater: res.warmWaterForHeating,
            hasDomesticHotWater: res.provideDomesticHotWater,
        },
        technicalQuestions: normalizeErrors('technicalQuestions', res),
        // Legal
        legalAgreementAccepted: res.termsAgree,
    };
    return errors;
};

export const b2who = (store) => {
    const { form, settings } = store;
    if (settings.userType === SESSION_USER_TYPES.PRO) return 'b2b'; // Professional portal
    if (form.userType === USER_TYPES.PRO || form.userType === USER_TYPES.KEY) return 'b2b'; // Pro/Key on public portal
    return 'b2c'; // Anon/End-user on public portal
};

export const getProductRangeId = (store) => {
    // store
    const {
        hasExistingInstallation,
        installationSerialKnown,
        installationKnown,
        existingInstallation,
    } = store.form;
    const { installation } = store.settings;

    if (hasExistingInstallation) {
        if (installation) return installation.productRange;
        if (existingInstallation?.productRangeId) return existingInstallation.productRangeId;
    } else {
        if (!installationSerialKnown) return null;
        if (installationKnown.productRange?.id) return installationKnown.productRange.id;
    }
    return null;
};

export const getNormalizeUserType = (store) => {
    const userType = store.form.userType || store.settings.userType;

    if (userType === 'customer' || userType === 'end-user') {
        return 'b2c';
    }
    if (userType === 'key-account' || userType === 'professional') {
        return 'b2b';
    }
    return null;
};

export const createInstallationName = (installation) => {
    const { customerName, accountNumber, productRangeName, address } = installation;
    const { street, number, box, postalCode, city } = address || {};
    const parts = [];
    const nameParts = [];
    if (customerName) nameParts.push(customerName);
    if (accountNumber) nameParts.push(`(${accountNumber})`);
    parts.push(nameParts.join(' '));
    if (productRangeName) parts.push(productRangeName);
    const addressParts = [];
    if (street) addressParts.push(street);
    if (street && number) addressParts.push(box ? `${number}${box}` : number);
    if (street) addressParts.push(',');
    if (postalCode) addressParts.push(postalCode);
    if (city) addressParts.push(city);
    parts.push(addressParts.join(' '));
    return parts
        .map((p) => p.trim())
        .filter((p) => !!p)
        .join(' - ');
};
