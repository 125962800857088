import Loader from '@common/components/Loader';
import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAaltraScriptInjection, usePostal } from './hooks';
import { useSchedulerTool } from './hooks/schedulerHooks';
import { useAppSelector } from './redux';
import actions from './redux/actions';
import { TOOL_STATE } from './type';

const App = (): JSX.Element | null => {
    const dispatch = useDispatch();
    // Fetch data from store
    const hasPageHistory = useAppSelector((state) => state.appState.history.length > 1);
    const isErrorPage = useAppSelector((state) => state.toolData.step === 'error-page');
    const toolState = useAppSelector((state) => state.appState.toolState);
    const installationUrl = useAppSelector((state) => state.settings.installationDetailUrl);
    const showBackButton = hasPageHistory && !isErrorPage;
    const title = i18n('schedulerTitle', false);
    const description = i18n('schedulerDescription', false);
    // Custom hooks
    usePostal();
    useSchedulerTool(dispatch);
    useAaltraScriptInjection(dispatch);

    const onNavigateBack = (): void => {
        dispatch(actions.scheduler.navigateSchedulerTool());
    };

    // Render loader when redirecting to installation details page
    if (toolState === null && installationUrl) return <Loader message="" />;

    if (toolState === TOOL_STATE.error) return null;

    // Render reservation scheduled confirmation banner if scheduler is unmounted
    if (toolState === TOOL_STATE.done) {
        return (
            <div className="alert alert-success" role="alert">
                <div className="alert-message">
                    <p>{i18n('reservationScheduleConfirmed')}</p>
                </div>
            </div>
        );
    }

    return (
        <>
            {!!title && <h2>{title}</h2>}
            {!!description && <p className="text-sm">{description}</p>}
            {showBackButton && (
                <div className="icon">
                    <a onClick={onNavigateBack}>
                        <Icons.Glyph.ChevronLeft />
                    </a>
                </div>
            )}
            <div id="external-tool-sbm-scheduler" />
        </>
    );
};

export default App;
