import trimFormActionLogic from './trimFormActionLogic';
import userTypeLogic from './userTypeLogic';
import servicePartnerLogic from './servicePartnerLogic';
import installationLogic from './installationLogic';
import materialsLogic from './materialsLogic';
import pricingInfoLogic from './pricingInfoLogic';
import submitLogic from './submitLogic';

export default [
    ...trimFormActionLogic,
    ...userTypeLogic,
    ...servicePartnerLogic,
    ...installationLogic,
    ...materialsLogic,
    ...pricingInfoLogic,
    ...submitLogic,
];
