/* eslint-disable no-console */
let _console = window.console;

let DEBUG = true;

const COLORS = Object.freeze({
    GREY: '#CCCCCC',
    DAIKIN: '#0099CC',
    BLUE: '#3D8299',
    GREEN: '#2EB88F',
    ORANGE: '#EE6124',
    RED: '#A8193E',
    PINK: '#E673CB',
    LIGHT_PINK: '#FBA7E7',
    PURPLE: '#8900E0',
    MAUVE: '#A37EF2',
});

export const STYLE: Record<string, string> = Object.freeze({
    GROUP_TITLE: 'font-weight: bold; ',
    DAIKIN: `color: ${COLORS.DAIKIN}; font-style: italic; `,
    DEFAULT: `color: ${COLORS.BLUE}; `,
    WARN: `color: ${COLORS.ORANGE}; `,
    ERROR: `color: ${COLORS.RED}; font-weight: bold; `,
    FATAL: `color: #FFFFFF; background-color: ${COLORS.RED}; `,
    API: `color: ${COLORS.GREEN}; `,
    SELSOFT: `color: ${COLORS.PINK}; `,
    SSI_INFO: `color: ${COLORS.LIGHT_PINK}`,
    SSI_PF_INFO: `color: ${COLORS.PURPLE}`,
    SOCKET: `color: ${COLORS.MAUVE}; `,
    FILLER: `color: ${COLORS.GREY}; `,
    ITALIC: `font-style: italic; `,
    BOLD: `font-weight: bold; `,
});

export const setDebug = (value: boolean): void => {
    DEBUG = value;
};

export const ___MUTE_NATIVE___ = (): void => {
    forceLog(
        ' 💀 OVERRIDE SET FOR window.console 💀 ',
        STYLE.FATAL,
        'REMOVE BEFORE GOING TO PRODUCTION',
    );
    if (!_console) _console = window.console;
    window.console = {
        ...window.console,
        log: () => {},
        info: () => {},
        error: () => {},
        warn: () => {},
        group: () => {},
        groupEnd: () => {},
    };
};

const toStr = (str: unknown): string => {
    return typeof str === 'object' || Array.isArray(str) ? JSON.stringify(str) : (str as string);
};

export const logDebug = (message: unknown, ...subMessages: string[]): void => {
    _console.log(message, ...subMessages);
};

const logMessage = (message: unknown, style = STYLE.DEFAULT, ...subMessages: unknown[]): void => {
    if (!_console || !DEBUG) return;
    // Log single or group
    if (!subMessages.length) {
        _console.log(`%c${message}`, style);
    } else {
        _console.group(`%c${message}`, style + STYLE.GROUP_TITLE);

        subMessages.forEach((subMessage) => {
            if (subMessage instanceof Error) {
                if (subMessage.message) _console.log(`%c${subMessage.message}`, style);
                if (subMessage.stack) _console.log(`%c${subMessage.stack}`, style);
            } else {
                _console.log(`%c${toStr(subMessage)}`, style);
            }
        });
        _console.groupEnd();
    }
};

export const forceLog = (
    message: unknown,
    style = STYLE.DEFAULT,
    ...subMessages: unknown[]
): void => {
    const debug = DEBUG;
    DEBUG = true;
    logMessage(toStr(message), style, ...subMessages);
    DEBUG = debug;
};

export const log = (message: unknown, style = STYLE.DEFAULT, ...subMessages: unknown[]): void => {
    logMessage(toStr(message), style, ...subMessages);
};

export const logRaw = (
    message: unknown,
    style = STYLE.DEFAULT,
    ...subMessages: unknown[]
): void => {
    logMessage(message, style, ...subMessages);
};

export const custom = (message: string, style: string, ...params: unknown[]): void => {
    if (!_console || !DEBUG) return;
    // Prefix with filler color
    let trueMessage = `%c${message}`;
    const colors = [STYLE.FILLER];
    // Create string
    for (let i = 0; i < params.length; i++) {
        trueMessage = trueMessage.replace(`{${i}}`, `%c${params[i]}%c`);
        colors.push(style, STYLE.FILLER);
    }
    // Log message with colors
    _console.log(trueMessage, ...colors);
};

export const warn = (message: unknown, ...messages: unknown[]): void => {
    logMessage(`[WARN] ${toStr(message)}`, STYLE.WARN, ...messages);
};

export const error = (message: unknown, ...messages: unknown[]): void => {
    logMessage(`[ERROR] ${toStr(message)}`, STYLE.ERROR, ...messages);
};

export const fatal = (message: unknown, ...messages: unknown[]): void => {
    logMessage(`[FATAL] ${toStr(message)}`, STYLE.FATAL);
    if (messages && messages.length && _console && !DEBUG) _console.error(messages);
};
