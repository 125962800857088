import i18n from '@common/i18n';
import axios from 'axios';
import { createLogic } from "redux-logic";
import { ACTIONS, fetchPricingInfo, setErrors, setPricingInfo } from '../actions';
import { SESSION_USER_TYPES } from '../reducers/settingsReducer';
import { getNormalizeUserType, getProductRangeId, parseErrors } from '../selectors';

const triggerFetchPricingInfoLogic = createLogic({
    type: [
        ACTIONS.SELECT_INSTALLATION,
        ACTIONS.SET_VISIT_TYPE,
        ACTIONS.SET_HAS_EXISTING_INSTALLATION,
        ACTIONS.SET_SERIAL_KNOWN,
        ACTIONS.SET_INSTALLATION_VIA_SERIAL,
        ACTIONS.SET_USER_TYPE,
    ],
    process({ getState }, dispatch, done) {
        const {
            hasExistingInstallation,
            existingInstallation,
            installationSerialKnown,
            visitType,
        } = getState().form;

        const productRangeId = getProductRangeId(getState());
        let productRangeCanBeEmpty = !hasExistingInstallation && (installationSerialKnown === false);
        if (existingInstallation && getState().settings.userType === SESSION_USER_TYPES.END) {
            productRangeCanBeEmpty = true;
        }
        if (visitType && (!!productRangeId || productRangeCanBeEmpty)) {
            dispatch(fetchPricingInfo());
        }
        done();
    },

});

const fetchPricingInfoLogic = createLogic({
    type: ACTIONS.FETCH_PRICING_INFO,
    process({ getState }, dispatch, done) {
        let pricingInfoUrl = getState().settings.urls.pricingInfo;
        const { visitType } = getState().form;
        const productRangeId = getProductRangeId(getState());
        const normalizedUserType = getNormalizeUserType(getState());

        pricingInfoUrl = pricingInfoUrl
            .replace('productRangeId=__productRangeId__&', productRangeId ? `productRangeId=${productRangeId}&` : '')
            .replace('__interventionType__', visitType)
            .replace('__userType__', normalizedUserType);

        axios.get(pricingInfoUrl)
            .then(res => {
                if (res.status === 200 && res.data) {
                    if (res.data.success && res.data.pricingInfo) {
                        dispatch(setPricingInfo(res.data.pricingInfo));
                    } else {
                        dispatch(setPricingInfo(null));
                    }
                } else {
                    dispatch(setErrors({ pricingInfo: i18n('unexpected_error') }));
                }
            })
            .catch((err) => {
                const errors = err?.response?.data?.errors;
                if (errors) dispatch(setErrors(parseErrors(errors)));
            })
            .finally(done);

    },
});

const resetPricingInfo = createLogic({
    type: [ACTIONS.SET_HAS_EXISTING_INSTALLATION, ACTIONS.SET_SERIAL_KNOWN],
    process({ getState, action }, dispatch, done) {
        const {
            hasExistingInstallation,
            installationKnown,
            installationSerialKnown,
            existingInstallation,
            pricingInfo,
        } = getState().form;

        // When changing value existing installation
        if (action.type === ACTIONS.SET_HAS_EXISTING_INSTALLATION) {
            if ((!hasExistingInstallation && (installationSerialKnown == null) && pricingInfo) // When no exisiting installation, installations serialNr is null but pricing info is known of previous
            || (hasExistingInstallation && !existingInstallation?.productRangeId && pricingInfo) // When exisiting installation, productRangeId from exisiting installation is not known but pricing info is known of previous
            || (!hasExistingInstallation && (installationSerialKnown === true) // When exisiting installation, installations serialNr is known, productRangeId from exisiting installation is not known and but pricing info is known of previous
                && !installationKnown?.productRange && pricingInfo)) {
                dispatch(setPricingInfo(null));
            }
        }

        //  When changing value existing installation
        if (action.type === ACTIONS.SET_SERIAL_KNOWN) {
            // When installation serialNr is known, no exisiting installation and pricing info of previous is known
            if (installationSerialKnown && !hasExistingInstallation && pricingInfo) {
                dispatch(setPricingInfo(null));
            }
        }
        done();
    },
});

export default [triggerFetchPricingInfoLogic, fetchPricingInfoLogic, resetPricingInfo];
