import * as Logger from '@common/Logger';
import { injectScript } from '@tsUtils';
import postal from 'postal';
import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { useAppSelector } from '../redux';
import actions from '../redux/actions';
import { TOOL_STATE } from '../type';

const CHANNEL_NAME = 'sbm scheduler';

declare global {
    interface Window {
        _pwaChannel: IChannelDefinition<unknown>;
    }
}

let pwaChannel: IChannelDefinition<unknown>;

export const usePostal = (): void => {
    useEffect(() => {
        // Create channel or fetch from window
        pwaChannel = window._pwaChannel || postal.channel(CHANNEL_NAME);
        // Expose rsnChannel on window object after creation
        window._pwaChannel = pwaChannel;
        // Log all postal events
        const logPostal = (data: unknown, env: IEnvelope<unknown>): void => {
            if (data) Logger.log(`[📯] ${env.topic}`, Logger.STYLE.DEFAULT, data);
            else Logger.log(`[📯] ${env.topic}`, Logger.STYLE.DEFAULT);
        };
        window._pwaChannel.subscribe('*.*', logPostal);
    }, []);
};

export const useAaltraScriptInjection = (dispatch: Dispatch): void => {
    // Fetch store data
    const scriptUrl = useAppSelector((state) => state.settings.schedulerJsUrl);
    // Script injection
    useEffect(() => {
        if (scriptUrl) {
            // Inject Aaltra script
            const scriptId = '__aaltra-bundle';
            injectScript(
                scriptId,
                scriptUrl,
                () => dispatch(actions.scheduler.setToolState(TOOL_STATE.injected)),
                () => dispatch(actions.scheduler.setToolState(TOOL_STATE.error)),
            );
        }
    }, [scriptUrl]);
};
