import { ActionsType, TOOL_SCHEDULER_ACTION_TYPES } from '../actions';
import { AppState } from '../../type';

const initialState: AppState = {
    installationId: '',
    basketId: undefined,
    history: [],
    toolState: null,
};

const appState = (state: AppState, action: ActionsType): AppState => {
    switch (action.type) {
        case TOOL_SCHEDULER_ACTION_TYPES.setToolState:
            return { ...state, toolState: action.toolState };
        case TOOL_SCHEDULER_ACTION_TYPES.update:
            // When naviating back we get an update call of the new current step, step already exisit in history
            if (state.history[state.history.length - 1] === action.data.step) return state;
            return {
                ...state,
                history: [...state.history, action.data.step],
            };
        case TOOL_SCHEDULER_ACTION_TYPES.navigate:
            return {
                ...state,
                history: state.history.slice(0, -1),
            };
        default:
            return { ...initialState, ...state };
    }
};

export default appState;
