import axios from 'axios';
import { createLogic } from 'redux-logic';
import { ACTIONS, suggestMaterials } from '../actions';

// Check if query is valid
const validateFetchMaterialsLogic = createLogic({
    type: ACTIONS.QUERY_MATERIALS,

    validate({ getState, action }, allow, reject) {
        const { query } = action;
        if (!query || query.length <= 2) { // Don't make requests for small queries
            reject(getState().materials.suggestions.length ? suggestMaterials() : null);
        } else {
            allow(action); // -> Advance to fetchMaterialsLogic
        }
    },
});

const fetchMaterialsLogic = createLogic({
    type: ACTIONS.QUERY_MATERIALS,
    debounce: 500,
    latest: true,

    process({ getState, action }, dispatch, done) {
        const { query } = action;
        const request = getState().settings.urls.materialCodes.replace('__query__', query);
        axios.get(request)
            .then(res => {
                if (res.status === 200 && res.data) dispatch(suggestMaterials(res.data));
            })
            .catch(() => { /* TODO */ })
            .finally(done);
    },

});

export default [validateFetchMaterialsLogic, fetchMaterialsLogic];
