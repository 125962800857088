import { ACTIONS } from '../actions';

export const USER_TYPES = {
    END: 'customer',
    KEY: 'key-account',
    PRO: 'professional',
};

export const VISIT_TYPES = {
    REPAIR: 'repair',
    MAINTENANCE: 'maintenance',
    COMMISSIONING: 'commissioning', // only for PRO usertype
};

export const CONTACT_TYPES = {
    MYSELF: 'myself',
    SERVICE_PARTNER: 'service-partner',
    SOMEONE_ELSE: 'other',
};

export const defaultState = {
    // ExistingInstallation.js
    hasExistingInstallation: true, // bool
    existingInstallation: undefined, // { id, name }
    // HasSbmAccount.js
    hasSbmAccount: undefined, // bool
    userType: undefined, // USER_TYPES
    // ServicePartner.js
    servicePartnerKnown: undefined, // bool
    servicePartner: undefined, // { id, name }
    serviceByDaikin: undefined, // bool
    // VisitDetails.js
    visitType: null, // VISIT_TYPE
    visitDate: '',
    isUrgent: false,
    // UserDetails.js
    userDetails: {},
    userAddress: {},
    // Contact.js
    contactType: CONTACT_TYPES.MYSELF, // myself | service-partner | someone-else
    contactPersonDetails: {}, // userDetails + legal checks
    // Comment.js
    comment: null, // string
    // InstallationAddress.js
    isSameAddress: null, // bool
    installationAddress: {}, // address
    // InstallationDetails.js
    installationSerialKnown: null, // bool (will be true for DE & FR)
    installationKnown: {
        material: null, // <string> materialCode
        serial: null, // string
        productRange: null, // string
    },
    installationUnknown: {
        energySources: [], // gas, oil, elec
        heatingWithWater: null, // bool (rad, convectors, ufh, ...)
        hasDomesticHotWater: null, // bool | null (i don't know)
    },
    // TechnicalQuestions.js
    technicalAnswers: {}, // { uuid: any }
    // Legal.js
    legalAgreementAccepted: false,
    isFetchingPricingInfo: false,
    pricingInfo: null,
};

const formReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_HAS_EXISTING_INSTALLATION:
            return { ...state, hasExistingInstallation: action.value };
        case ACTIONS.SELECT_INSTALLATION:
            return { ...state, existingInstallation: action.value, requestDaikinAssistance: false };
        case ACTIONS.SET_HAS_SBM_ACCOUNT:
            return { ...state, hasSbmAccount: action.value };
        case ACTIONS.SET_USER_TYPE:
            return { ...state, userType: action.userType };
        case ACTIONS.SET_REQUEST_DAIKIN_ASSISTANCE:
            return { ...state, requestDaikinAssistance: true };
        case ACTIONS.SET_VISIT_DATE:
            return { ...state, visitDate: action.visitDate };
        case ACTIONS.SET_VISIT_TYPE:
            return { ...state, visitType: action.visitType };
        case ACTIONS.QUERY_SERVICEPARTNERS:
            return { ...state, servicePartner: null };
        case ACTIONS.SELECT_SERVICEPARTNER:
            return { ...state, servicePartner: action.servicePartner };
        case ACTIONS.SET_SERVICEPARTNER_KNOWN:
            return { ...state, servicePartnerKnown: action.value };
        case ACTIONS.SET_SERVICE_BY_DAIKIN:
            return { ...state, serviceByDaikin: action.value };
        case ACTIONS.SET_URGENCY:
            return { ...state, isUrgent: action.value };
        case ACTIONS.SET_USER_DETAILS:
            return { ...state, userDetails: { ...state.userDetails, ...action.data } };
        case ACTIONS.SET_USER_ADDRESS:
            return { ...state, userAddress: { ...state.userAddress, ...action.data } };
        case ACTIONS.SET_CONTACT_TYPE:
            return { ...state, contactType: action.contactType };
        case ACTIONS.SET_CONTACT_PERSON_DETAILS:
            return { ...state, contactPersonDetails: { ...state.contactPersonDetails, ...action.data } };
        case ACTIONS.SET_COMMENT:
            return { ...state, comment: action.comment };
        case ACTIONS.SET_IS_SAME_ADDRESS:
            return { ...state, isSameAddress: action.isSameAddress };
        case ACTIONS.SET_INSTALLATION_ADDRESS:
            return { ...state, installationAddress: { ...state.installationAddress, ...action.data } };
        case ACTIONS.SET_SERIAL_KNOWN:
            return { ...state, installationSerialKnown: action.known };
        case ACTIONS.SET_INSTALLATION_VIA_SERIAL:
            return { ...state, installationKnown: { ...state.installationKnown, ...action.data } };
        case ACTIONS.SET_INSTALLATION_VIA_DESCRIPTION:
            return { ...state, installationUnknown: { ...state.installationUnknown, ...action.data } };
        case ACTIONS.SET_TECHNICAL_ANSWERS:
            return { ...state, technicalAnswers: { ...state.technicalAnswers, ...action.data } };
        case ACTIONS.SET_LEGAL_AGREEMENT_ACCEPTED:
            return { ...state, legalAgreementAccepted: action.accepted };
        case ACTIONS.FETCH_PRICING_INFO:
            return { ...state, isFetchingPricingInfo: true };
        case ACTIONS.SET_PRICING_INFO:
            return { ...state, isFetchingPricingInfo: false, pricingInfo: action.pricingInfo };
        default:
            return state || defaultState;
    }
};

export default formReducer;
