/* eslint-disable func-names */
import boiler from '@boiler';
import i18n from '@common/i18n';
import { createLogicMiddleware } from 'redux-logic';
import App from './App';
import logic from './logic';
import reducers from './reducers';
import { VISIT_TYPES, defaultState as defaultFormState } from './reducers/formReducer';
import { SESSION_USER_TYPES, defaultState as defaultSettingsState } from './reducers/settingsReducer';
import { createInstallationName, forceInstallationViaSerial } from './selectors';

const defaultLabels = {
    // Common
    yes: 'Yes',
    no: 'No',
    loading: 'Loading, please wait...',
    email: 'E-mail address',
    phone: 'Phone number',
    company: 'Company',
    first_name: 'First name',
    last_name: 'Last name',
    street: 'Street',
    street_number: 'Number',
    pobox: 'PO Box',
    postal: 'Postal code & city',
    postal_prompt: 'Enter a postal code',
    postal_format: 'Format: 9876',
    city_select: 'Select...',
    province: 'Province',
    country: 'Country',
    // ExistingInstallation.js
    request_for_assistance: 'New request: assistance',
    has_existing_installation: 'Existing installation?',
    existing_installation: 'Installation',
    existing_installation_placeholder: 'Search installation by address, name, ...',
    // HasSbmAccount.js
    has_sbm_account: 'Do you have an account on Stand By Me?',
    sign_in: 'Sign-in on Stand By Me',
    sign_in_info: '',
    // UserType.js
    user_type: 'You are a',
    user_type_end_user: 'End user',
    user_type_key_account: 'Key-Account',
    user_type_key_account_info: '',
    user_type_professional: 'Professional',
    // ServicePartner.js
    service_partner_known: 'Do you know your service partner?',
    your_service_partner: 'Your service partner',
    intervention_by: 'You want an intervention from',
    intervention_by_daikin: 'Daikin',
    intervention_by_service_partner: 'Your service partner',
    no_service_partner: 'Please select a valid service partner',
    contact_service_partner: 'Contact your service partner',
    // VisitDetails.js
    request_daikin_assistance_title: 'If however you request assistance from Daikin',
    request_daikin_assistance: 'Request assistance from Daikin',
    request: 'Request',
    visit_type: 'Type of visit',
    visit_type_repair: 'Repair',
    visit_type_maintenance: 'Maintenance',
    visit_type_commissioning: 'Commissioning',
    desired_date_of_visit: 'Desired date of visit',
    desired_date_of_visit_helptext: '',
    urgency: 'Urgency',
    urgency_normal: 'Normal',
    urgency_urgent: 'Urgent',
    urgency_urgent_info: '',
    // UserDetails.js
    personal_details: 'Personal details',
    // Contact.js
    your_details: 'Your details',
    contact_details: 'Contact details',
    contact_person_details: 'Details of the person to contact',
    contact_person: 'Contact for this request',
    contact_type_myself: 'Myself',
    contact_type_service_partner: 'My Service Partner',
    contact_type_someone_else: 'Someone else',
    contact_person_title: 'Details of the person to contact',
    contact_person_legal_title: 'Legal declarations',
    contact_person_legal_1: 'I hereby declare that, having provided all information to person concerning the processing of personal data for the purpose of registering on the platform “Stand by Me”, in line with applicable legislation, the person has given the consent to me as Installer to the processing of his/her personal data.',
    contact_person_legal_2: 'I further declare that the person is informed that Stand by Me is a platform owned by Daikin and that Daikin will receive and use the above personal data to send to the person an invitation via e-mail, on my behalf/on behalf of my company, asking for the completion of the registration on "Stand by Me".',
    // Comment.js
    comment: 'Comment',
    comment_placeholder: 'Include error code(s), ...',
    // InstallationAddress.js
    installation_address: 'Installation address',
    installation_address_same: 'The same as your contact address provided before?',
    // InstallationDetails.js
    installation_details: 'Installation details',
    installation_serial_known: 'Do you know the type and serial number of the indoor unit?',
    installation_serial_info_link: 'Where to find this?',
    installation_serial_info_title: 'Where to find Model & Serial number',
    installation_serial_info_a: 'Model',
    installation_serial_info_b: 'Serial number',
    // InstallationDetailsViaDescription.js
    installation_description_energysources: 'What is the energy source of the system?',
    installation_description_heating_with_water: 'The system uses warm water for heating',
    installation_description_heating_with_water_help: '(e.g. radiators, convectors, underfloor heating, ...)',
    installation_description_has_dhw: 'Does the system provide domestic hot water?',
    gas: 'Gas',
    oil: 'Oil',
    electricity: 'Electricity',
    unknown: 'I don\'t know',
    // InstallationDetailsViaSerial.js
    installation_indoor_unit: 'Type of indoor unit',
    installation_serial_number: 'Serial number of the indoor unit',
    installation_product_range: 'Select the product range',
    customer_is_known: 'This installation is already registered with a different email-address',
    // Legal.js
    gdpr_statement: 'Daikin will process the personal data you have provided in the above form to the extent necessary and for the sole purpose of contacting you, in accordance with the European General Data Protection Regulation (GDPR). For more information please read our {data_protection_policy}.',
    data_protection_policy: 'Data Protection Policy',
    terms_and_conditions_title: 'Terms and conditions',
    privacy_statement_title: 'Privacy statement',
    invoice_notice: 'A request could eventually include a cost. The invoice will be sent to you as the party sending the request',
    pricing_list: 'pricing list',
    pricing_list_url_b2b: 'http://www.daikin.be/pricing_list/REPLACE_THIS_URL_B2B',
    pricing_list_url_b2c: 'http://www.daikin.be/pricing_list/REPLACE_THIS_URL_B2C',
    // Legal.js - Anonymous
    legal_agreement_anon: 'By clicking the checkbox, you agree to our {terms_of_use} and pricing list and that you have read our {data_protection_policy}',
    terms_of_use: 'Terms of Use',
    legal_iframe_anonymous: 'http://www.daikin.be/legal_iframe/anonymous/REPLACE_THIS_URL',
    // Legal.js - Professional
    legal_agreement_pro: 'Yes, I agree to the {professional_terms_and_conditions} and the {privacy_statement} and the pricing list',
    professional_terms_and_conditions: 'professional terms & conditions',
    privacy_statement: 'privacy statement',
    legal_iframe_professional: 'http://www.daikin.be/legal_iframe/professional/REPLACE_THIS_URL',
    // Submit.js
    submit: 'Send request',
    unexpected_error: 'An unexpected error occurred, please try again later',
};

// Strip out unwanted values & map some settings to form-state
const mapInputToState = input => {
    const { labels, settings } = input;
    // Pass labels to i18n
    i18n.set({ ...defaultLabels, ...labels });
    // Create initial state
    const returnState = {
        settings: { ...defaultSettingsState, ...settings },
        form: { ...defaultFormState },
    };
    // Check settings
    let { installation } = settings;
    const { installations, userType, customer } = settings;
    // Autoselect installation
    if (installations?.length) installation = installations[0];
    // Default visitType is repair on pro-portal
    const isPro = userType === SESSION_USER_TYPES.PRO;
    const isEnd = userType === SESSION_USER_TYPES.END;
    returnState.form.visitType = isPro ? VISIT_TYPES.REPAIR : null;
    returnState.form.hasExistingInstallation = isPro || isEnd;
    // Germany & France is always via installation-serial
    if (forceInstallationViaSerial(returnState)) returnState.form.installationSerialKnown = true;
    // Pass existing installation to form
    if (installation) {
        const { id, customerInvitationDetails, keyAccountInvitationDetails } = installation;
        returnState.form.hasExistingInstallation = true;
        returnState.form.existingInstallation = {
            id,
            name: createInstallationName(installation),
        };
        // Pass customerDetails to form
        returnState.form.contactPersonDetails = {
            firstName: customerInvitationDetails?.firstName || null,
            lastName: customerInvitationDetails?.lastName || null,
            email: customerInvitationDetails?.email || keyAccountInvitationDetails?.email || null,
            phone: customerInvitationDetails?.phoneNumber || null,
        };
    }
    // Prefill end-user data with logged in customer data
    if (customer && userType === SESSION_USER_TYPES.END) {
        returnState.form.userDetails = {
            firstName: customer.firstName || null,
            lastName: customer.lastName || null,
            email: customer.email || null,
            phone: customer.phoneNumber || null,
        };
    }
    return returnState;
};

(function () {
    boiler.initAppInstances(
        App,
        'rfa-form',
        reducers,
        mapInputToState,
        [createLogicMiddleware(logic)],
    );
}());
