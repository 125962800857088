import React from 'react';
import Loader from '@common/components/Loader';
import { useSelector } from 'react-redux';

const PricingInfo = () => {
    const isFetchingPricingInfo = useSelector(store => store.form.isFetchingPricingInfo);
    const pricingInfo = useSelector(store => store.form.pricingInfo);

    return (
        <>
            {isFetchingPricingInfo && <Loader />}
            {(pricingInfo && !isFetchingPricingInfo)
                && (
                    <div
                        className='mt-4'
                        style={{ overflowY: 'scroll', maxHeight: '450px' }}
                        dangerouslySetInnerHTML={{ __html: pricingInfo }}
                    />
                )}
        </>
    );
};

export default PricingInfo;
